import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss']
})
export class LandingpageComponent {

  isPhonePortrait = false;
  trustColumns = 2;
  applicationsColumns = 3;

  constructor(private responsive: BreakpointObserver) {

  }


  ngOnInit(){

    this.responsive.observe(Breakpoints.HandsetPortrait)
    .subscribe(result => {

      this.isPhonePortrait = false;

      if (result.matches) {
        this.isPhonePortrait = true;
        this.trustColumns = 1;
        this.applicationsColumns = 1;
      }

    });

  }
}