<app-textbox>
    <h1>Privacy policy</h1>

    <p>We take your privacy seriously. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website color33.io, including any other media form, media channel, mobile website, or mobile application related or connected thereto (collectively, the "Site"). Please read this privacy policy carefully. If you do not agree with the terms of this Privacy Policy, please do not access the site.</p>

    <h2>Information we collect</h2>
    <p>We collect personal information that you voluntarily provide to us when expressing an interest in obtaining information about us or our products and services, when participating in activities on the Site or otherwise contacting us.</p>

    <p>The personal information that we collect depends on the context of your interactions with us and the Site, the choices you make, and the products and features you use. The personal information we collect may include:</p>

    <ul>
        <li>Name</li>
        <li>Email address</li>
    </ul>

    <h2>Use of information</h2>
    <p>We use personal information collected via our Site for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests ("Business Purposes"), in order to enter into or perform a contract with you ("Contractual"), with your consent ("Consent"), and/or for compliance with our legal obligations ("Legal Reasons").</p>

    <h2>Disclosure of information</h2>
    <p>We may share information we have collected about you in certain situations. Your information may be disclosed as follows:</p>
    <ul>
        <li>By law or to protect rights: If we believe the release of information about you is necessary to respond to legal process, to investigate or remedy potential violations of our policies, or to protect the rights, property, and safety of others, we may share your information as permitted or required by any applicable law, rule, or regulation.</li>
        <li>Otherwise, no data will be disclosed.</li>
    </ul>

    <h2>Security</h2>
    <p>We take reasonable steps to protect the information provided via the Site from loss, misuse, and unauthorized access, disclosure, alteration, or destruction. However, no Internet or email transmission is ever fully secure or error-free. Therefore, you should take special care in deciding what information you send to us via email or submit on our Site.</p>

    <h2>Changes to this Privacy Policy</h2>
    <p>We may update this Privacy Policy from time to time. The updated version will be effective as soon as it is accessible.</p>

    <h2>Contact us</h2>
    <p>If you have any questions, concerns, or complaints regarding this Privacy Policy, we encourage you to contact us using the details below:</p>
    <p><a href="mailto:color33@spatial-services.com">color33@spatial-services.com</a></p>

    <p>Last update: 14.03.2024</p>
  </app-textbox>