import {NgModule, Injectable} from '@angular/core';
import {AuthModule, OpenIdConfiguration} from 'angular-auth-oidc-client';

import {StsConfigStaticLoader, StsConfigLoader} from 'angular-auth-oidc-client';

import APP_CONFIG from "../../assets/app-config.json"

@Injectable({providedIn: 'root'})
export class ConfigService {
    getConfig(): OpenIdConfiguration {
        return {
            authority: APP_CONFIG.AUTH.AUTHORITY,
            authWellknownEndpointUrl: APP_CONFIG.AUTH.WELLKNOWN_ENDPOINT_URL, //this is a hack, for the 'broken' config from authority (keycloak)
            redirectUrl: window.location.origin,
            postLogoutRedirectUri: window.location.origin,
            clientId: 'siamaas-web-client',
            scope: 'openid profile offline_access email',
            responseType: 'code',
            silentRenew: true,
            useRefreshToken: true,
            renewTimeBeforeTokenExpiresInSeconds: 30,
            ignoreNonceAfterRefresh: true,
        };
    }
}

const authFactory = (configService: ConfigService) => {
    const config = configService.getConfig();
    return new StsConfigStaticLoader(config);
};

@NgModule({
    imports: [
        AuthModule.forRoot({
            loader: {
                provide: StsConfigLoader,
                useFactory: authFactory,
                deps: [ConfigService],
            },
        }),
    ],
    providers: [],
    exports: [AuthModule],
})
export class AuthConfigModule {
}
