import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NavigationComponent } from './navigation/navigation.component';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { NavButtonComponent } from './navigation/nav-button/nav-button.component';
import { SettingsComponent } from './settings/settings.component';
import { JobComponent } from './job/job.component';
import { JoblistComponent } from './joblist/joblist.component';
import { MeComponent } from './me/me.component';
import { NotificationComponent } from './me/notification/notification.component';
import { JobPanelComponent } from './joblist/job-panel/job-panel.component';
import { MapViewComponent } from './job/map-view/map-view.component';
import {HttpClientModule, HttpClientXsrfModule} from '@angular/common/http';
import { AuthConfigModule } from './auth/auth-config.module';
import { FooterComponent } from './footer/footer.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { StatusBarComponent } from './job/status-bar/status-bar.component';
import { TimeViewComponent } from './job/time-view/time-view.component';
import { ConfirmDialogComponent } from './dialog/confirm/confirm-dialog.component';
import { NotificationDialogComponent } from './dialog/notification/notification-dialog.component';
import { ResultsComponent } from './job/results/results.component';
import { DownloadComponent } from './job/results/download/download.component';
import { ListComponent } from './job/results/list/list.component';
import { ResultComponent } from './job/results/list/result/result.component';
import { WhatsNewComponent } from './resources/whats-new/whats-new.component';
import { GettingStartedComponent } from './resources/getting-started/getting-started.component';
import { ManualComponent } from './resources/manual/manual.component';
import { ResourcesPageMenuStarterComponent } from './resources/resources-page-main/resources-page-menu/starter/resources-page-menu-starter.component';
import { ResourcesPageMenuComponent } from './resources/resources-page-main/resources-page-menu/resources-page-menu.component';
import { ContactComponent } from './resources/contact/contact.component';
import { CallToActionComponent } from './resources/resources-page-main/call-to-action/call-to-action.component';
import { ResourcesPageSectionComponent } from './resources/resources-page-main/resources-page-section/resources-page-section.component';
import { ResourcesPageNoteComponent } from './resources/resources-page-main/resources-page-note/resources-page-note.component';
import { ResourcesPageImageComponent } from './resources/resources-page-main/resources-page-image/resources-page-image.component';
import { ResourcesWrapperComponent } from './resources/resources-page-main/resources-wrapper.component';
import {MatProgressBar, MatProgressBarModule} from "@angular/material/progress-bar";
import {MatMenuModule} from '@angular/material/menu';
import {FileSaverModule} from "ngx-filesaver";
import {NgcCookieConsentModule, NgcCookieConsentConfig} from 'ngx-cookieconsent';
import { CookiesComponent } from './legal/cookies/cookies.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { SecurityComponent } from './legal/security/security.component';
import { ImprintComponent } from './legal/imprint/imprint.component';
import { TextboxComponent } from './legal/textbox/textbox.component';
import {ClipboardModule} from '@angular/cdk/clipboard';
import { LabelsViewComponent } from './job/labels-view/labels-view.component';
import {MatChipsModule} from '@angular/material/chips';
import { ProcessingOptionsComponent } from './job/processing-options/processing-options.component';
import { PricingComponent } from './resources/pricing/pricing.component';
import { PriceOptionComponent } from './resources/pricing/price-option/price-option.component';
import { ResourcesPageTitleComponent } from './resources/resources-page-main/resources-page-title/resources-page-title.component';
import { ResourcesComponent } from './resources/resources.component';
import { AgreementsComponent } from './legal/agreements/agreements.component';
import { NewsItemComponent } from './resources/whats-new/news-item/news-item.component';
import {LoginRedirectComponent} from "./login-redirect/login-redirect.component";

const cookieConfig:NgcCookieConsentConfig = {
  "cookie": {
    "domain": "color33.io"
  },
  "position": "bottom",
  "theme": "edgeless",
  "palette": {
    "popup": {
      "background": "#000000",
      "text": "#ffffff",
      "link": "#ffffff"
    },
    "button": {
      "background": "#f1d600",
      "text": "#000000",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "This website uses cookies if you login to your account.",
    "dismiss": "Got it!",
    "deny": "Refuse cookies",
    "link": "Learn more",
    "href": "https://color33.io/legal/cookies",
    "target": "_self",
    "policy": "Cookie Policy"
  }
};

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    LandingpageComponent,
    NavButtonComponent,
    SettingsComponent,
    JobComponent,
    JoblistComponent,
    MeComponent,
    NotificationComponent,
    JobPanelComponent,
    MapViewComponent,
    ResourcesComponent,
    FooterComponent,
    NotFoundComponent,
    StatusBarComponent,
    TimeViewComponent,
    ConfirmDialogComponent,
    NotificationDialogComponent,
    ResultsComponent,
    DownloadComponent,
    ResultComponent,
    ListComponent,
    WhatsNewComponent,
    GettingStartedComponent,
    ManualComponent,
    ResourcesPageMenuStarterComponent,
    ResourcesPageMenuComponent,
    ContactComponent,
    CallToActionComponent,
    ResourcesPageSectionComponent,
    ResourcesPageNoteComponent,
    ResourcesPageImageComponent,
    ResourcesWrapperComponent,
//    MatProgressBar,
    CookiesComponent,
    PrivacyComponent,
    SecurityComponent,
    ImprintComponent,
    TextboxComponent,
    LabelsViewComponent,
    ProcessingOptionsComponent,
    PricingComponent,
    PriceOptionComponent,
    ResourcesPageTitleComponent,
    AgreementsComponent,
    NewsItemComponent,
    LoginRedirectComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatBadgeModule,
    MatExpansionModule,
    MatStepperModule,
    MatTabsModule,
    MatInputModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatDialogModule,
    MatCheckboxModule,
    MatProgressBarModule,
    MatSliderModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({cookieName: 'csrftoken', headerName: 'X-CSRFToken'}),
    AuthConfigModule,
    FileSaverModule,
    ClipboardModule,
    MatChipsModule,
    NgcCookieConsentModule.forRoot(cookieConfig)
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
