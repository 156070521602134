<div class="footer mat-toolbar mat-primary">
  <p class="legal" [ngClass]="this.navigationCollapsed ? 'collapsed' : 'full'">
    <a  href="/legal/cookies" target="_self">Cookie Policy</a>
    <span class="separator">|</span>
    <a  href="/legal/agreements" target="_self">License and Service Agreements</a>
    <span class="separator">|</span>
    <a href="/legal/privacy" target="_self">Privacy</a>
    <span class="separator">|</span>
    <a href="/legal/security" target="_self">Security</a>
    <span class="separator">|</span>
    <a href="/legal/imprint" target="_self">Imprint</a>
  </p>
  <p class="about">color33 provided by Spatial Services GmbH & Department of Geoinformatics - Z_GIS</p>
</div>