import { Component } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { MatOption } from '@angular/material/core';
import { Input, Output, EventEmitter } from '@angular/core';
import { JobInputs } from 'src/app/jobs.service';

export type ProvideOriginalSatelliteImagesType = null | "standard" | "cloudOptimised";

@Component({
  selector: 'app-processing-options',
  templateUrl: './processing-options.component.html',
  styleUrls: ['./processing-options.component.scss'],
})
export class ProcessingOptionsComponent {

  @Input() existingJob!: JobInputs;
  @Input() defaultProcessingOptions !: any;
  @Output() notify: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private _formBuilder: FormBuilder,
    private httpClient: HttpClient
  ) {}

  /*
   * This variable will determine whether we set the options to readonly or not
   * It is used in the templates and defined by whether we receive an existing job
   * or not.
   */
  readOnly= true;

  /*
   * These are the processing options that can be altered by the users. See below
   * the custom selection.
   */
  processingOptions = {
    "epsgCode": null as number | null,
    "outputLayer": ["Siam33"],
    "cloudCover": {
      "min": 0,
      "max": 100
    },
    "provideOriginalSatelliteImages": null as ProvideOriginalSatelliteImagesType,
    "provideCloudOptimisedOutput": true,
  };


  /*
   * Formcontrol for the EPSG Code Search
   */
  EPSGCodeSearch = new FormControl();

  /*
   * This keeps the filtered EPSG Codes if the user begins to type.
   */
  filteredEPSGForSearch!: Observable<string[]>;

  /*
   * This keeps all EPSG codes. It will be populated during init of the component.
   */
  EPSGCodes: string[] = [];

  /*
   * Keeps the WKT for the EPSG Code. Will be fetched when the user selects one
   * and will be displayed in the frontend.
   */
  EPSGCodeWKT: string = "No additional information available for this EPSG code";
  EPSGCodeLabel: string = "Search EPSG Code";

  /*
   * Keeps some more information about possible output layers. Will be used for
   * labels, tooltips etc in the frontend.
   */
  processingOutputsDetail = [
    {
      key: "Siam18",
      label: "Color names with 18 categories",
      description: "The lowest number of categories. Some input images (e.g. VHR images with low amount of bands) can be only processed up to 18 categories."
    },
    {
      key: "Siam33",
      label: "Color names with 33 categories",
      description: "The shared categories that can be derived from a lot of images, including Landsat and Sentinel-2. Use this for the best trade-off between flexibility and granularity."
    },
    {
      key: "Siam48",
      label: "Color names with 48 categories",
      description: "48 categories"
    },
    {
      key: "Siam96",
      label: "Color names with 96 categories",
      description: "The highest number of categories. It has the most granularity but can not be derived from all satellite images."
    },
    {
      key: "Brightness",
      label: "Brightness",
      description: "A continous variable that indicates how bright a pixel is."
    },
    {
      key: "Greenness",
      label: "Greenness",
      description: "A continuous variable that indicates how green a pixel is. You can use it to derive vegetation status as it is more expressive than the NDVI."
    },
    {
      key: "HazePentarnaryMask",
      label: "Haze Pentarnary Mask",
      description: "A categorical variable with an estimate about the haze in an image."
    },
    {
      key: "VegetationBinaryMask",
      label: "Vegetation Binary Mask",
      description: "A binary mask for vegetation occurrence."
    },
    {
      key: "VegetationTrinaryMask",
      label: "Vegetation Trinary Mask",
      description: "A trinary mask for vegetation ocurrence."
    },
    {
      key: "BaresoilBuiltupMask",
      label: "Baresoil Builtup Mask",
      description: "A mask that indicates a baresoild or builtup pixel."
    },
    {
      key: "CloudTrinaryMask",
      label: "Cloud Trinary Mask",
      description: "A trinary mask for clouds."
    },
    {
      key: "WaterTrinaryMask",
      label: "Water Trinary Mask",
      description: "A trinary mask for water."
    },
    {
      key: "ShadowTrinaryMask",
      label: "Shadow Trinary Mask",
      description: "A trinary mask for shadow."
    },
    {
      key: "UrbanAreaMask",
      label: "Urban Area Mask",
      description: "A mask for urban areas."
    },
  ];

  /*
   * Form group for the output layers that matches the mat-checkbox
   */
  processingOutputs = this._formBuilder.group({
    Siam18: false,
    Siam33: true,
    Siam48: false,
    Siam96: false,
    Brightness: false,
    Greenness: false,
    HazePentarnaryMask: false,
    VegetationBinaryMask: false,
    VegetationTrinaryMask: false,
    BaresoilBuiltupMask: false,
    CloudTrinaryMask: false,
    WaterTrinaryMask: false,
    ShadowTrinaryMask: false,
    UrbanAreaMask: false,
  });

  /*
   * Form group for the selection of provision of origional satellite images 
   * that matches the mat-checkbox
   */
  provideOriginalSatelliteImages = this._formBuilder.group({
    standard: false,
    cloudOptimised: false,
  });

  /*
   * Form group for the selection of cloud-optimised formats
   * that matches the mat-checkbox
   */
  provideCloudOptimisedOutput = this._formBuilder.group({
    cloudOptimised: true,
  });

  ngOnInit() {

    /*
     * Trigger loading of all EPSG Codes
     */
    this._loadEpsgCodes();

    /*
     * Creating a function that filters the codes from the user input
     */
    this.filteredEPSGForSearch = this.EPSGCodeSearch.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    /*
     * If we receive an existing job, set everything to readOnly and apply the
     * inputs we received. If an input is not existing, we use the default processing
     * option.
     */
    this.readOnly = this.existingJob !== undefined;

    if (this.readOnly){
      if (this.existingJob.cloud_cover_max){
        this.setCloudCoverFilterProcessingOption(this.existingJob.cloud_cover_max, "cloudCoverMax");
      } else {
        this.setCloudCoverFilterProcessingOption(this.defaultProcessingOptions.cloudCover.max, "cloudCoverMax");
      }

      if (this.existingJob.cloud_cover_min){
        this.setCloudCoverFilterProcessingOption(this.existingJob.cloud_cover_min, "cloudCoverMin");
      } else {
        this.setCloudCoverFilterProcessingOption(this.defaultProcessingOptions.cloudCover.min, "cloudCoverMin");
      }

      if (this.existingJob.epsg_code){
        this.setEPSGCode(this.existingJob.epsg_code);
      } else {
        this.setEPSGCode(this.defaultProcessingOptions.epsgCode);
      }

      if (this.existingJob.provide_cloud_optimised_output){
        this.setCloudOptimisedOutput(this.existingJob.provide_cloud_optimised_output);
      } else {
        this.setCloudOptimisedOutput(this.defaultProcessingOptions.provideCloudOptimisedOutput);
      }

      if (this.existingJob.provide_original_satellite_images) {
        this.setOriginalSatelliteImageOutput(this.existingJob.provide_original_satellite_images as unknown as ProvideOriginalSatelliteImagesType);
      } else {
        this.setOriginalSatelliteImageOutput(this.defaultProcessingOptions.provideOriginalSatelliteImages);
      }

      if (this.existingJob.output_layers) {
        this.setOutputLayers(this.existingJob.output_layers);
      } else {
        this.setOutputLayers(this.defaultProcessingOptions.outputLayer);
      }
    }
  }

  /*
   * Loading the array of EPSG codes into the EPSGCodes array. This is triggered
   * in ngOnInit.
   */
  private _loadEpsgCodes(): void {
    this.httpClient.get<string[]>('assets/epsg-codes/codes-all.json')
      .subscribe(codes => {
        this.EPSGCodes = codes;
      });
  }

  /*
   * FUnction to filter values based on user input.
   */
  private _filter(value: string): string[] {
    return this.EPSGCodes.filter(option => option.includes(value));
  }

  /*
   * Set the EPSG Code from user input
   */
  setEPSGCodeProcessingOption(option: MatOption): void {
    this.httpClient.get('assets/epsg-codes/EPSG-CRS-' + option.value + '.wkt', {responseType: 'text'})
        .subscribe(wkt => {
          this.processingOptions.epsgCode = option.value;
          this.EPSGCodeWKT = wkt;
          this.notify.emit("keep");
        });
  }

  /*
   * Set the EPSG Code from existing job
   */
  setEPSGCode(epsgCode: number | null): void {
    if (epsgCode !== null){
      this.httpClient.get('assets/epsg-codes/EPSG-CRS-' + epsgCode + '.wkt', {responseType: 'text'})
      .subscribe(wkt => {
        this.EPSGCodeWKT = wkt;
        this.EPSGCodeLabel = epsgCode.toString();
        this.processingOptions.epsgCode = epsgCode;
      });
    } else {
      this.EPSGCodeWKT = "Image EPSG codes were used. They can vary from image to image (depending on their geographical location)."
      this.EPSGCodeLabel = "Image EPSG codes."
      this.processingOptions.epsgCode = null;
    }
    this.EPSGCodeSearch.disable();
  }

  /*
   * Clear the EPSG Code input
   */
  clearEPSGCode(): void{
    this.processingOptions.epsgCode = null;
    this.EPSGCodeWKT = "No additional information available for this EPSG code";
    this.notify.emit("keep");
  }

  /*
   * Set the cloud cover from user input OR existing job
   */
  setCloudCoverFilterProcessingOption(value: number, bound: string){
    if (bound === "cloudCoverMin") {
      this.processingOptions.cloudCover.min = value;
    }
    if (bound === "cloudCoverMax") {
      this.processingOptions.cloudCover.max = value;
    }
  }

  /*
   * Set switch for cloud-optimised processing from user input.
   */
  setCloudOptimisedOutputProcessingOption(): void {
    if (typeof this.provideCloudOptimisedOutput.value.cloudOptimised === "boolean"){
      this.processingOptions.provideCloudOptimisedOutput = this.provideCloudOptimisedOutput.value.cloudOptimised;
    }
  }

  /*
   * Set switch for cloud-optimised processing from existing job.
   */
  setCloudOptimisedOutput(output: any): void {
    this.processingOptions.provideCloudOptimisedOutput = output;
  }

  /*
   * Set switch for providing original satellite image from user input.
   */
  setOriginalSatelliteImageOutputProcessingOption(type: ProvideOriginalSatelliteImagesType): void {
    const provideOriginalSatelliteImages = this.provideOriginalSatelliteImages.value;

    if (provideOriginalSatelliteImages.standard && provideOriginalSatelliteImages.cloudOptimised) {
      this.processingOptions.provideOriginalSatelliteImages = type;
      this.provideOriginalSatelliteImages.patchValue({
        [type === 'standard' ? 'cloudOptimised' : 'standard']: false,
      });
    } else if (!provideOriginalSatelliteImages.standard && !provideOriginalSatelliteImages.cloudOptimised) {
      this.processingOptions.provideOriginalSatelliteImages = null;
    } else {
      this.processingOptions.provideOriginalSatelliteImages = 
        provideOriginalSatelliteImages.standard ? 'standard' : 'cloudOptimised';
    }
  }

  /*
   * Set switch for providing original satellite image from existing job.
   */
  setOriginalSatelliteImageOutput(type: ProvideOriginalSatelliteImagesType): void {
    this.provideOriginalSatelliteImages.patchValue({
      [type === 'standard' ? 'cloudOptimised' : 'standard']: false,
    });
  }

  /*
   * Set output layers from user input.
   */
  setOutputLayersProcessingOption() :void{
    if (Object.values(this.processingOutputs.value).every(value => value === false)){
      this.processingOutputs.patchValue({
        Siam33: true,
      });
    }
    let selectedSIAMOptions: string[] = Object.keys(this.processingOutputs.value).filter((key, index) => Object.values(this.processingOutputs.value)[index])
    this.processingOptions.outputLayer = selectedSIAMOptions;
  }

  /*
   * Set output layers from existing job.
   */
  setOutputLayers(outputLayers: string[]): void {
    Object.keys(this.processingOutputs.controls).forEach(layer => {
      // Check if the key is in the array of keys to set to true
      if (outputLayers.includes(layer)) {
        // If the key is in the array, set the checkbox value to true
        this.processingOutputs.get(layer)?.setValue(true);
      } else {
        // Otherwise, set the checkbox value to false
        this.processingOutputs.get(layer)?.setValue(false);
      }
    });
  }

  /*
   * Helper to disable input in case the readonly flag is set.
   */
  disabledInput(event: Event) {
    event.preventDefault();
    event.stopPropagation();
  }

  /*
   * Getter-Function to allow the parent object to retrieve the custom options.
   */
  getProcessingOptions(): any {
    return this.processingOptions;
  }

  /*
   * Getter-Function to allow the parent object to retrieve the default options
   * in case the user did not change anything.
   */
  getDefaultProcessingOptions(): any {
    /*
    * These are the default processing options. They will be used if the user do
    * not select custom options.
    */
    return this.defaultProcessingOptions;
  }

  resetProcessingOptions(): void {

      this.setCloudCoverFilterProcessingOption(this.defaultProcessingOptions.cloud_cover_max, "cloudCoverMax");
      this.setCloudCoverFilterProcessingOption(this.defaultProcessingOptions.cloud_cover_min, "cloudCoverMin");
      this.setEPSGCode(this.defaultProcessingOptions.epsgCode);
      this.setCloudOptimisedOutput(this.defaultProcessingOptions.provideCloudOptimisedOutput);
      this.setOriginalSatelliteImageOutput(this.defaultProcessingOptions.provideOriginalSatelliteImages);
      this.setOutputLayers(this.defaultProcessingOptions.outputLayer);
  }

  hasCustomEPSGCode(): boolean {
    return this.processingOptions.epsgCode !== null;
  }

  hasCustomCloudCover(): boolean {
    return this.processingOptions.cloudCover.min !== 0 || this.processingOptions.cloudCover.max !== 100;
  }

  hasCustomOutputLayers(): boolean {
    return this.processingOptions.outputLayer.length !== 1 || this.processingOptions.outputLayer[0] !== "Siam33";
  }

  hasCustomProvideOriginalSatelliteImages(): boolean {
    return this.processingOptions.provideOriginalSatelliteImages !== null;
  }

  hasCustomProvideCloudOptimisedOutput(): boolean {
    return this.processingOptions.provideCloudOptimisedOutput !== true;
  }

  hasCustomProcessingOptions(): boolean {
    return this.hasCustomEPSGCode() || this.hasCustomCloudCover() || this.hasCustomOutputLayers() || this.hasCustomProvideOriginalSatelliteImages() || this.hasCustomProvideCloudOptimisedOutput();
  }
}
