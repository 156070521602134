<app-textbox>
    <h1>Imprint</h1>

    <p>color33 is provided by Spatial Services GmbH</p>

    <h2>Company information</h2>


    <table class="imprint">
      <tr>
        <td>Registered company name</td>
        <td>Spatial Services GmbH</td>
      </tr>
      <tr>
        <td>Description of the company (self-chosen)</td>
        <td>Geoinformatics solutions</td>
      </tr>
      <tr>
        <td>Phone</td>
        <td>+43 662 276084</td>
      </tr>
      <tr>
        <td>Web</td>
        <td>http://www.spatial-services.com</td>
      </tr>
      <tr>
        <td>E-Mail</td>
        <td>office@spatial-services.com</td>
      </tr>
      <tr>
        <td>VAT Reg. No. (UID)</td>
        <td>ATU69984528</td>
      </tr>
      <tr>
        <td>Commercial register number</td>
        <td>442646a</td>
      </tr>
      <tr>
        <td>Authority according to ECG (e-commerce law)</td>
        <td>Municipal administration – Stadt Salzburg</td>
      </tr>
      <tr>
        <td>Additional controlling authority (according to ECG)</td>
        <td>None</td>
      </tr>
      <tr>
        <td>Applicable legislation</td>
        <td>http://www.ris2.bka.gv.at (DE)</td>
      </tr>
      <tr>
        <td>Legal form</td>
        <td>Company with limited liability</td>
      </tr>
      <tr>
        <td>Additional information according to §14 UGB:</td>
        <td>Spatial Services GmbH <br /> Schillerstraße 30 <br /> 5020 Salzburg, Austria <br /> FN 442646a, Landesgericht Salzburg <br /></td>
      </tr>
    </table>

    <p>Last update: 14.03.2034</p>
</app-textbox>