import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-resources-page-menu',
  templateUrl: './resources-page-menu.component.html',
  styleUrls: ['./resources-page-menu.component.scss']
})
export class ResourcesPageMenuComponent {
  @Input() size!: string;
  @Input() context: String = "";

  protected selected: string = ""

}
