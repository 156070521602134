<div class="wrapper">
  <h1>
    How can we help?</h1>

  <app-resources-page-menu
  [size]="this.isPhonePortrait? 'small' : 'large'"
  ></app-resources-page-menu>

  <div class="audience">

    <div class="avatar">
      <div class="image-container">
        <img class="image" src="assets/user-programmer.png" />
      </div>
        <div class="info">
          <h2 class="group">Are you an app developer?</h2>
          <div class="message">
            <p>color33 provides an API for processing imagery in near-real-time based on custom, spatial-temporal AOIs, and offers a reliable, cloud-based approach for semantic enrichment. Relieve the pain to maintain your own EO data infrastructure and just use color33 for what you need, when you need it.</p>
          </div>
      </div>
    </div>

    <div class="avatar">
      <div class="image-container">
       <img class="image" src="assets/user-scientist.png" />
      </div>
        <div class="info">
          <h2 class="group">Are you a scientist?</h2>
          <div class="message">
            <p>color33 transforms multispectral EO imagery into spectral categories that you can directly utilise based on your domain knowledge of what has been observed rather than having to develop EO algorithms completely from scratch. Use the categories to stratify sample collection for ML routines or build classes using the categories and knowledge-based rule sets. color33 provides you a stable translation layer between reflectance values and classes to get you away from the imagery and closer to wherever your research questions are taking you.</p>
          </div>
        </div>
    </div>

    <div class="avatar">
      <div class="image-container">
        <img class="image" src="assets/user-student.png" />
      </div>
        <div class="info">
          <h2 class="group">Are you a student?</h2>
          <div class="message">
            <p>color33 provides you an initial interpretation of imagery with spectral categories that you can work with immediately. Let the stable categories guide you through the variability and complexity and multidimensionality of multispectral Earth observation imagery.</p>
          </div>
      </div>
    </div>
  </div>
</div>
