<mat-card>
  <mat-card-header>
    <mat-card-title>{{this.title}}</mat-card-title>
    <mat-card-subtitle>Published: {{this.date}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <div>
      <ng-content></ng-content>
    </div>
  </mat-card-content>
</mat-card>

