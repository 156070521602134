import { Component, Input, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/dialog/confirm/confirm-dialog.component';
import {Job, JobsService} from "../../jobs.service";
import {Router} from "@angular/router";
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-job-panel',
  templateUrl: './job-panel.component.html',
  styleUrls: ['./job-panel.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class JobPanelComponent {

  @Input() job!: Job;

  panelOpenState = false;

  confirmDeleteMessage: string = "Do you want to continue deleting the job? This can not be reversed.";

  constructor(public dialog: MatDialog,
              private jobsService: JobsService,
              private router: Router,
              private oidcSecurityService: OidcSecurityService) {}

  delete(that: any): void {
    //TODO(SR) as this is called from the ConfirmDialog we can't use "this" here. So we do this little "that" dance.
    that.jobsService
        .deleteJob(that.job.jobID)
        .subscribe((result: any)=>{
          alert("Job deleted sucessfully");
          window.location.reload();
        })
  }

  rerunJob(): void {
    this.jobsService.executeJob(this.job.inputs)
        .subscribe((result: Job) => {
          alert("Job started sucessfully.");
          this.router.navigate(['job', result.jobID]);
        })
  }

  downloadResults(): void {
    this.oidcSecurityService.getAccessToken()
        .subscribe((token) => {
          window.open(`${this.jobsService.JOBS_URL}/${this.job.jobID}/results/zip?token=${token}`, "_blank");
        });
  }

  openDeleteDialog(enterAnimationDuration: string, exitAnimationDuration: string, message: string): void {
    this.dialog.open(ConfirmDialogComponent, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
      data: {
        message: message,
        thisPtr: this,
        confirmAction: this.delete,
      },
    });
  }
}
