<mat-expansion-panel (opened)="panelOpenState = true"
                     (closed)="panelOpenState = false"
                    [ngClass]="job.inputs.dry_run? 'dry-run' : 'wet-run'"
>
    <mat-expansion-panel-header class="job-header">
        <mat-panel-title class="title">
            <span class="status-icon">
                <span class="succeeded material-symbols-outlined" *ngIf="this.job.status ==='successful'">task_alt</span>
                <span class="failed material-symbols-outlined" *ngIf="this.job.status ==='failed'">cancel</span>
                <span class="started material-symbols-outlined" *ngIf="this.job.status ==='running'">clock_loader_40</span>
                <span class="started material-symbols-outlined" *ngIf="this.job.status ==='accepted'">hourglass</span>
            </span>
            <span class="name">
                Job #{{this.job.jobID}}
            </span>
        </mat-panel-title>
        <mat-panel-description>
            <span class="progress">
                <span *ngIf="this.job.inputs.dry_run" class="dry-run-label">
                    <span class="material-symbols-outlined">image_not_supported</span>
                    <span>dry run</span>
                </span>
                <span *ngIf="!this.job.inputs.dry_run">{{this.job.progress}}%</span>&nbsp;
                <mat-progress-bar class="progress-bar" *ngIf="!this.job.inputs.dry_run" [value]="this.job.progress"></mat-progress-bar>&nbsp;
            </span>
            <span>Last&nbsp;update: {{this.job.updated | date:'medium'}}</span>&nbsp;
        </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="job-info">
        <app-status-bar [createdTimestamp]="job.created" [startedTimestamp]="job.started"
                        [finishedTimestamp]="job.finished"></app-status-bar>

        <p class="message">
            <span class="material-symbols-outlined">description</span>
            <span>{{this.job.message}}</span>
        </p>
    </div>

    <div class="job-actions">
        <button mat-stroked-button routerLink="/job/{{this.job.jobID}}">
            <mat-icon>info</mat-icon>
            <span>Details</span>
        </button>
        <button mat-stroked-button [disabled]="this.job.status !== 'successful'"
                (click)="rerunJob()">
            <mat-icon>repeat</mat-icon>
            <span>Re-run</span>
        </button>
        <button mat-stroked-button [disabled]="(this.job.status !== 'successful' || this.job.inputs.dry_run)"
                (click)="downloadResults()">
            <mat-icon>get_app</mat-icon>
            <span>Download all</span>
        </button>
        <button mat-stroked-button color="warn" [disabled]="this.job.status !== 'successful' && this.job.status !== 'failed'"
                (click)="openDeleteDialog('200ms', '200ms', this.confirmDeleteMessage)">
            <mat-icon color="warn">delete_forever</mat-icon>
            <span>Delete job and results</span>
        </button>
    </div>
</mat-expansion-panel>