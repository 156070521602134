<section class="section">
  <span class="list-section">
    <mat-checkbox 
                  [checked]="allComplete"
                  [indeterminate]="someComplete()"
                  (change)="setAll($event.checked)">
      {{result.name}}
    </mat-checkbox>
  </span>
  <span class="list-section">
    <ul>
      <li *ngFor="let file of result.files">
        <mat-checkbox [(ngModel)]="file.completed"
                      (ngModelChange)="updateAllComplete()">
          {{file.name}}
        </mat-checkbox>
      </li>
    </ul>
  </span>
</section>