<mat-form-field *ngIf="readonly == false">
  <mat-label>Enter a date range</mat-label>
  <mat-date-range-input [rangePicker]="picker" [formGroup]="range" >
    <input
      matStartDate
      formControlName="start"
      #dateRangeStart
      placeholder="Start date"
    >
    <input
      matEndDate
      formControlName="end"
      #dateRangeEnd
      placeholder="End date"
      (dateChange)="dateRangeChangeTrigger()"
    >
  </mat-date-range-input>
  <mat-hint>DD/MM/YYYY – DD/MM/YYYY</mat-hint>
  <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-date-range-picker #picker></mat-date-range-picker>
</mat-form-field>

<mat-form-field *ngIf="readonly == true" >
  <mat-label>Your selected date range</mat-label>
  <mat-date-range-input>
    <input
      matStartDate
      #dateRangeStart
      [value]="this.selectedStartDate"
    >
    <input
      matEndDate
      #dateRangeEnd
      [value]="this.selectedEndDate"
    >
  </mat-date-range-input>
</mat-form-field>