<app-textbox>
  <h1>End-User License Agreement (EULA) and Service Level Agreement (SLA)</h1>

  <p>color33 is a service provided by the company Spatial Services GmbH, which is located in Salzburg, Austria.</p>

  <p>The service and the results are provided under the terms specified in the end-user license agreement (EULA) and the service-level agreement (SLA) as follows.</p>

  <h2>End-User License Agreement (EULA)</h2>
  <p>The EULA governs your access to and use of the color33 fully automated, application-independent Big Earth Observation ("EO") data service ("color33") provided Spatial Services Ltd. ("Spatial Services"). </p>

  <h3>1. Acceptance</h3>

  <p>By accessing or using color33, you agree to be bound by the terms and conditions of this EULA. If you do not agree to all the terms and conditions of this EULA, you are not authorized to access or use color33.</p>

  <h3>2. Definitions</h3>
  <ul>
    <li>Big EO Data: Refers to large volumes of Earth observation data collected from various sources such as satellites, aerial imagery, and sensors.</li>
    <li>color33: The fully automated, application independent Big EO data service provided by the Company.</li>
    <li>User: Any individual or entity who accesses or uses the service.</li>
  </ul>

  <h3>3. License Grant</h3>
  <p>Spatial Services grants you a non-exclusive, non-transferable, limited license to access and use color33 for your commercial, educational, and research purposes. You may not:</p>
  <ul>
    <li>Sub-license color33 to any third party.</li>
    <li>Modify, reverse engineer, decompile, or disassemble color33.</li>
    <li>Use color33 for any illegal or unauthorized purpose.</li>
  </ul>

  <h3>4. Data Ownership</h3>

  <ul>
    <li>The Company retains ownership of all data provided through color33.</li>
    <li>You are granted a non-exclusive right to use the data for your commercial, educational, and research purposes.</li>
    <li>You may not redistribute, sell, or otherwise share the data with any third party without the prior written consent of Spatial Services.</li>
  </ul>

  <h3>5. User Obligations</h3>
  <ul>
    <li>You are responsible for maintaining the confidentiality of your account information.</li>
    <li>You are responsible for all activity that occurs under your account.</li>
    <li>You agree to comply with all applicable laws and regulations in your use of color33.</li>
  </ul>

  <h3>6. Disclaimer of Warranty</h3>
  <p>The service is provided “as is” and without warranty of any kind, express or implied. Spatial Services disclaims all warranties, including any implied warranties of merchantability, fitness for a particular purpose, title, and non-infringement.</p>

  <h3>7. Limitation of Liability</h3>
  <p>Spatial Services shall not be liable for any direct, indirect, incidental, special, consequential, or exemplary damages arising out of or relating to the use of color33, even if Spatial Services has been advised of the possibility of such damages.</p>

  <h3>8. Term and Termination</h3>
  <p>This EULA shall be effective upon your first access to color33 and shall remain in effect until terminated by either party. Spatial Services may terminate this EULA at any time for any reason, with or without notice. You may terminate this EULA by ceasing to access or use color33.</p>

  <h3>9. Governing Law</h3>
  <p>This EULA shall be governed by and construed in accordance with the laws of Austria.</p>

  <h3>10. Entire Agreement</h3>
  <p>This EULA constitutes the entire agreement between you and Spatial Services regarding your access to and use of color33 and supersedes all prior or contemporaneous communications, representations, or agreements.</p>

  <h3>11. Severability</h3>
  <p>If any provision of this EULA is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall remain in full force and effect.</p>

  <h3>12. Contact Information</h3>
  <p>If you have any questions about this EULA, please contact Spatial Services at: <a href="mailto:color33@spatial-services.com">color33@spatial-services.com</a></p>

  <h2>Service Level Agreement</h2>
  <p>This Service Level Agreement ("SLA") establishes the agreed-upon performance standards between Spatial Services Ltd. ("Spatial Services") and the user of their fully automated, application-independent Big Earth Observation data service (“color33”).</p>

  <h3>1. Services:</h3>

  <p>The Spatial Services´s color33 provides:</p>

  <ul>
    <li>Automated Access: 24/7 access to fully automated, application independent semantic enrichment routines for various EO data through color33.io.</li>
    <li>Data Delivery: Timely delivery of requested EO data in designated formats.</li>
    <li>Data Quality: Provision of data that meets specific accuracy and completeness standards.</li>
    <li>Service Uptime: Uptime guarantee for color33 (except for maintenance).</li>
  </ul>

  <h3>2. Performance Metrics:</h3>

  <ul>
    <li>Availability: color33 will be available at least 98% of the time during any given month.</li>
    <li>Data Delivery Time: Semantically enriched data will be delivered directly after the end of the processing. The time depends on the necessary processing steps.</li>
    <li>Data Completeness: Data delivered will be at least 95% complete.</li>
  </ul>

  <h3>3. Service Credits:</h3>

  <p>In the event color33 fails to meet the agreed-upon performance metrics, Spatial Services shall provide the following service credits:</p>
  <ul>
    <li>Unavailability: For each hour beyond the guaranteed uptime threshold, an amount of 10 free credits will be applied.</li>
    <li>Delayed Data Delivery: An amount of 10 free credits be applied for each delay beyond the agreed delivery time.</li>
    <li>Data Incompleteness: A certain number of free credits will be issued based on the severity and extent of the incompleteness.</li>
  </ul>

  <h3>4. Service Monitoring:</h3>
  <p>Spatial Services will continuously monitor the performance of color33 and report any SLA violations promptly to the user.</p>

  <h3>5. User Responsibilities:</h3>
  <ul>
    <li>The user is responsible for reporting any suspected SLA violations to Spatial Services within a reasonable timeframe.</li>
    <li>Maintaining a stable internet connection is the user's responsibility for uninterrupted service access.</li>
  </ul>

  <h3>6. Exclusions:</h3>
  <ul>
    <li>Force Majeure events (natural disasters, power outages, etc.) are beyond Spatial Services control and will not be considered SLA violations.</li>
    <li>Scheduled maintenance downtime will be communicated beforehand and excluded from uptime calculations.</li>
  </ul>

  <h3>7. Dispute Resolution:</h3>
  <p>Any dispute arising from this SLA shall be settled through mutual negotiation. In case of unresolved issues, the dispute shall be referred to the regional court of Salzburg according to its rules.</p>

  <h3>8. Term and Termination:</h3>

  <h4>8.1 Termination by Licensor</h4>
  <p>color33 may terminate this SLA for cause upon 30 days' written notice to you. Cause for termination by color33 shall include, but not be limited to:</p>

  <ul>
    <li>Your material breach of this SLA which is not cured within 30 days' written notice from color33;</li>
    <li>Your repeated violation of the acceptable use policies set forth in the main Service Agreement; or</li>
    <li>Your cessation of business operations.</li>
  </ul>
  <p>color33 may also terminate this SLA immediately upon written notice to you if you become bankrupt, insolvent, or subject to similar proceedings.</p>

  <h4>8.2 Effect of Termination</h4>
  <p>Upon termination of this SLA for any reason:</p>

  <ul>
    <li>All licenses granted hereunder shall immediately terminate;</li>
    <li>You shall cease all use of color33; and</li>
    <li>color33 shall have no obligation to provide you with any further access to the service or any data stored thereunder.</li>
  </ul>

  <h4>8.3 Data Availability Post-Termination</h4>
  <p>In the event of termination of this SLA, you shall have the right to request a data download of your processed results for a period of 30 days following termination. Licensor shall use commercially reasonable efforts to comply with such request. Licensor shall have no obligation to store any raw data or unprocessed results following termination.</p>

  <h4>9. Contact Information:</h4>
  <p>If you have any questions about color33 privacy policy, the data we hold on you, or you would like to exercise one of your data protection rights, please do not hesitate to contact us. Also, for any inquiries regarding the SLA, please contact Spatial Services at: <a href="mailto:color33@spatial-services.com">color33@spatial-services.com</a></p>

</app-textbox>