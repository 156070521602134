<app-resources-wrapper context="manual">
  <app-resources-page-title
    [icon]="'menu_book'"
    [title]="'User Manual'">
  </app-resources-page-title>

  <app-resources-page-section title="Introduction">
    <p>Welcome to the user manual for color33, designed to provide automated spectral categorisation of satellite imagery.</p>

    <p>Satellite imagery has become an increasingly important tool in various industries, including agriculture, environmental monitoring, and urban planning. With the growth of satellite constellations and advances in remote sensing technology, there is now an abundance of high-resolution satellite images available for analysis. However, processing and analyzing these images can be a challenging task that requires specialized software and expertise. This user manual provides an overview of color33, which is designed to help users extract meaningful information from satellite imagery quickly and efficiently. Whether you're a researcher, analyst, or professional in the field, this manual will guide you through the various features and functionalities of our software and help you to maximize its potential.</p>

    <p>There are two options of using color33</p>
    <ul>
      <li>The graphical user interface (GUI) - this Web application</li>
      <li>The command-line interface (CLI)</li>
    </ul>

    <p>The GUI is a user-friendly, visual interface that allows users to interact with the software using icons, menus, and other graphical elements. It is ideal for users who are not familiar with command-line interfaces and prefer a more intuitive way of interacting with software. With the GUI, it is possible to graphically select the images for processing and downloading the result. On the other hand, the CLI provides a more streamlined and efficient way of interacting with the software through the use of text commands. It is ideal for users who are experienced with command-line interfaces and prefer a more programmatic way of interacting with the software. With the CLI, it is possible to automate repetitive tasks, and integrate the software into larger software pipelines. Ultimately, both the GUI and CLI offer unique benefits and cater to different usage scenarios, allowing users to choose the option that best suits their needs and preferences.</p>

    <p>This manual describes the GUI and gives information on how to download the CLI. The CLI has its own manual, which is delivered as a README.md file with the software.</p>
  </app-resources-page-section>

  <app-resources-page-section title="Structure">


    <mat-grid-list cols="4" class="navigation-description">
      <mat-grid-tile class="image-element">
        <img src="/assets/manual/manual-navigation.png" />
      </mat-grid-tile>
      <mat-grid-tile colspan="3">
        <div class="text-element">


        <ul>
		<h3><b>Navigation</b></h3>
	      <p>The navigation bar on the left side provides quick and easy access to some of the most commonly used features. Here is a brief description of each button:</p>
          <li><strong>Home:</strong> This button will take you back to the home screen, which is the landing page that is shown when you access color33 for the first time.</li>
          <li><strong>New Job:</strong> This button allows you to create a new job, which is the primary unit in color33. A job can include one or more satellite images, along with optional processing parameters.</li>
          <li><strong>Show Jobs:</strong> This button will display a list of all the jobs that you have created, along with their current status and other relevant information. You can use this feature to track the progress of your jobs and access their results.</li>
          <li><strong>Help:</strong> This button provides access to the color33 documentation, where you can find detailed instructions and information about how to use the various features and functions. The help documentation includes both a getting-started page and a manual.</li>
        </ul>

        <p>By using the navigation bar, you can quickly and easily access some of the most important features of the software, regardless of where you are in the software's workflow.</p>

	   <h3><b>Account</b></h3>

			<p>You can access your account settings by clicking the user icon on the top-right. The account page shows information and additional data.</p>
      </div>
      </mat-grid-tile>
    </mat-grid-list>

  </app-resources-page-section>

  <app-resources-page-section title="Usage">


    <h3><b>Processing images based on a geographic selection</b></h3>

    <p>For processing images based on their spatio-temporal location, click "New Job" on the navigation panel (left side).</p>

    <p>The selection process is twofold. First, you need to select an area-of-interest by clicking on the granules that cover your chosen area. The granules you select will be highlighted (You can select multiple granules by pressing SHIFT during the selection process). Second, you need to select a time interval of interest. Simply scroll down to view the time selection.</p>

    <app-resources-page-image file="/assets/manual/manual-geo-selection-1.png">
      Graphical user interface to select an area of interest.
    </app-resources-page-image>

    <p>You can toggle the option for further adjustment of the processing results via the "Custom Options" button available through the symbol to the top-right (three dots). If you have set everything according to your requirements, you can submit the job.</p>

    <app-resources-page-image file="/assets/manual/manual-custom-options.png">
      Custom options for adjusting the processing results.
    </app-resources-page-image>

    <app-resources-page-note>
        You need to click "Validate with dry-run" (top-right) before you’ll be able to submit the job. After successful validation, the job will be listed as a new „dry run“ entry in the Job List (which you can access via the „Show Jobs“ button in the navigation panel to the left). From there you’ll be able to finalize processing (i.e. rerun the job as a wet run and download the results).
    </app-resources-page-note>

  <h3><b>Show Jobs</b></h3>

  <p>A job is an executed process with specific parameters, which you set as described in the section above. To review succeeded, failed, running, or submitted jobs, you can click the button <b>Show Jobs</b> in the navigation panel on the left side.</p>

  <app-resources-page-image file="/assets/manual/manual-list-jobs.png">
    Listing all jobs.
  </app-resources-page-image>

  <p>The list gives you an initial overview and provides quick interaction options. You can click on one of the entries to get more detailed information as well as delete or re-run the selected job. To get the full details about one job, click on <b>details</b></p>

  <p>The details page shows all settings and configurations of a job and allows selection of results to download.</p>

  <h3><b>Show job details and download results</b></h3>

  <p>The detail view of any job shows parameters and allows to download the associated results</p>

  <app-resources-page-image file="/assets/manual/manual-show-results.png">
    Show job detals.
  </app-resources-page-image>

  <p>To get more details, scroll down this page. At the bottom are the results listed as an interactive list, which allows you to select individual or all results for download</p>

  <app-resources-page-image file="/assets/manual/manual-download-options.png">
    Select files to download.
  </app-resources-page-image>

  </app-resources-page-section>
  <app-resources-page-section title="Trouble Shooting">
    <p>Common potential errors will be shown here.</p>
  </app-resources-page-section>

  <app-resources-page-section title="Other Access Options">
    <p>If you want to embed color33 into regular workflows or your own application, please contact us for recommendations. </p>
  </app-resources-page-section>

</app-resources-wrapper>