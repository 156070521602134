import {Component, Output, OnInit, EventEmitter} from '@angular/core';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Observable, switchMap, tap} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {LoginResponse, OidcSecurityService} from "angular-auth-oidc-client";
import {Router} from "@angular/router";
import {FormBuilder} from "@angular/forms";
import {HttpClient, HttpHeaders, HttpResponse} from "@angular/common/http";
import {CookieService} from "ngx-cookie-service";
import { environment } from './../../environments/environment';

import { ConfirmDialogComponent } from '../dialog/confirm/confirm-dialog.component';
import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NotificationDialogComponent } from 'src/app/dialog/notification/notification-dialog.component';

import { MatDialog } from '@angular/material/dialog';
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {
    @Output("collapseNavigation") collapseNavigation: EventEmitter<boolean> = new EventEmitter();

    navigationCollapsed = false;
    isAuthenticated = false;
    userEmail: string = "foo@mail.invalid";
    username: string = "foo";

    collapsed = false;
    isPhonePortrait = false;

    loginForm: SafeHtml = "<p>test</p>";

    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map(result => result.matches),
            shareReplay()
        );

    constructor(private breakpointObserver: BreakpointObserver,
                private oidcSecurityService: OidcSecurityService,
                private router: Router,
                private dialog: MatDialog,
                private formBuilder: FormBuilder,
                private http: HttpClient,
                private cookieService: CookieService,
                private sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        console.log(`${this} ngOnInit()`);

        this.collapsed = localStorage.getItem("navbar-collapsed") === "f"? false : true;

        this.oidcSecurityService.isAuthenticated()
            .subscribe((value) => this.isAuthenticated = value);

        this.oidcSecurityService.getUserData()
            .subscribe((userdata) => this.updateUserdata(userdata));

        this.oidcSecurityService.checkAuth()
            .subscribe((loginResponse: LoginResponse) => {
                const {isAuthenticated, userData, accessToken, idToken, configId} = loginResponse;
                this.isAuthenticated = isAuthenticated;
                if (isAuthenticated) {
                    this.updateUserdata(loginResponse.userData);
                }
            });

        /*
         * Use breakpoint observer to check the screen size
         */
        this.breakpointObserver.observe(Breakpoints.Handset)
        .subscribe(result => {

            this.isPhonePortrait = false;
            if (result.matches) {
            /*
                * If this is on a small screen / phone, we don't collapse the
                * navigation and use only large icons.
                */
            this.isPhonePortrait = true;
            this.collapsed = false;
            }

        });

        this.fetchLogin().subscribe((response) => {

            const unsafeHtml = response.body ? response.body : "<p>error</p>"
            this.loginForm = this.sanitizer.bypassSecurityTrustHtml(unsafeHtml);
        });
    }

    collapse() {
        this.collapsed = !this.collapsed;
        localStorage.setItem("navbar-collapsed", this.collapsed? "t" : "f");
        this.navigationCollapsed = !this.navigationCollapsed;
        this.collapseNavigation.emit(this.navigationCollapsed);
    }

    logout() {
        this.oidcSecurityService.logoffAndRevokeTokens()
            .subscribe((foo) => {
                this.dialog.open(NotificationDialogComponent, {
                    width: '250px',
                    enterAnimationDuration: "200ms",
                    exitAnimationDuration: "200ms",
                    data: {
                        message: "You have been logged out.",
                        thisPtr: this
                    },
                });
                localStorage.clear();
                this.router.navigate(['/']);
            })
    }

    // login() {
    //
    //     if(localStorage.getItem("cookieConsent") !== "allow") {
    //         this.dialog.open(NotificationDialogComponent, {
    //             width: '250px',
    //             enterAnimationDuration: "200ms",
    //             exitAnimationDuration: "200ms",
    //             data: {
    //                 message: "You need to accept the cookie (below) to login. Otherwise, the information about your session can not be stored and you can not use color33. You can delete the cookie afterwards. There are no cookies for advertisement or tracking.",
    //                 thisPtr: this
    //             },
    //         })
    //     }
    //     /*
    //      * If a user is on a smaller devices, we give them a hint that the viewing
    //      * experience may be limited. We let the user confirm to proceed.
    //      */
    //     if (this.isPhonePortrait) {
    //         this.dialog.open(ConfirmDialogComponent, {
    //             width: '250px',
    //             enterAnimationDuration: '200ms',
    //             exitAnimationDuration: '200ms',
    //             data: {
    //                 message: "Starting and viewing jobs is not yet optimised for your device. Some parts may not be shown properly. Do you want to continue?",
    //                 thisPtr: this,
    //                 confirmAction: () => {
    //                     this.oidcSecurityService.authorize();
    //                 },
    //             },
    //         });
    //     } else {
    //         this.oidcSecurityService.authorize();
    //     }
    // }

    private updateUserdata(userdata: any) {
        if (userdata) {
            this.username = `${userdata.name} (${userdata.preferred_username})`;
            this.userEmail = userdata.email;
        } else {
            this.username = "";
            this.userEmail = "";
        }
    }

    fetchLogin(): Observable<HttpResponse<string>> {
        return this.http.get(environment.API.LOGIN_URL, {observe: 'response', responseType: 'text',  withCredentials: true});
    }

    login(): void {
        this.http.get(environment.API.LOGIN_URL, {observe: 'response', responseType: 'text',  withCredentials: true})
            .pipe(
                switchMap((response) => {
                    const regexp = new RegExp("<input type=\"hidden\" name=\"csrfmiddlewaretoken\" value=\"(.+)\">");
                    const match = regexp.exec(response.body || '');
                    const csrfmiddlewaretoken= match ? match[1] : '';

                    const headers = new HttpHeaders({
                        'X-XSRF-TOKEN': csrfmiddlewaretoken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    })
                    const shittyHack = new URLSearchParams();
                    shittyHack.append('csrfmiddlewaretoken', csrfmiddlewaretoken);

                    return this.http.post(environment.API.LOGIN_URL,
                        shittyHack.toString(),
                        {withCredentials: true, observe: 'response', responseType: 'text', headers: headers})
                })
            )
            .subscribe((response) => {
                    console.log(response)
                    console.log(response.url)
                    window.location.href = response.url || ''
            });
    }
}
