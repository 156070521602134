<div>
  <mat-horizontal-stepper [linear]="true" #stepper>

    <ng-template matStepperIcon="pending">
      <mat-icon>pending</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="done">
      <mat-icon>done</mat-icon>
    </ng-template>

    <mat-step
      [completed]="createdTimestamp != null"
      state="done">
      <ng-template matStepLabel>Created<br />{{createdTimestamp | date:'medium'}}</ng-template>
    </mat-step>

    <mat-step
      [completed]="startedTimestamp != null"
      state="pending">
      <ng-template matStepLabel>Started<br />{{startedTimestamp | date:'medium'}}</ng-template>
    </mat-step>
    
    <mat-step
      [completed]="finishedTimestamp != null"
      state="pending">
      <ng-template matStepLabel>Finished<br />{{finishedTimestamp | date:'medium'}}</ng-template>

  </mat-step>

  </mat-horizontal-stepper>
</div>