import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-me',
  templateUrl: './me.component.html',
  styleUrls: ['./me.component.scss']
})
export class MeComponent implements OnInit {
  username: string = "";
  email: string = "";
  name: string = "";

  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  ngOnInit() {
    this.oidcSecurityService.getUserData()
        .subscribe((userdata) => {
          if (userdata) {
            this.username = userdata.preferred_username;
            this.email = userdata.email;
            this.name = userdata.name;
          }
        })
  }
}
