import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-resources-wrapper',
  templateUrl: './resources-wrapper.component.html',
  styleUrls: ['./resources-wrapper.component.scss']
})
export class ResourcesWrapperComponent {
  @Input() context!: String;
}
