<mat-expansion-panel (opened)="panelOpenState = true"
  (closed)="panelOpenState = false">
  <mat-expansion-panel-header>
    <mat-panel-title>
    Your processing has been finished.
    </mat-panel-title>
    <mat-panel-description>

      <ng-template [ngIf]="this.status ==='succeeded'">
        <span class="succeeded status-icon">
          <span class="material-symbols-outlined">
            task_alt
          </span>
        </span>
      </ng-template>

      <ng-template [ngIf]="this.status ==='failed'">
        <span class="failed status-icon">
          <span class="material-symbols-outlined">
            cancel
          </span>
        </span>
      </ng-template>

    description
    </mat-panel-description>
  </mat-expansion-panel-header>
  <p>Your processing has been finsihed and ready for download: <span><button mat-raised-button>Download</button></span></p>

  <div class="button-row">
    <button mat-button>Mark as read</button>
    <button mat-button color="accent">Delete</button>
  </div>

</mat-expansion-panel>