import {Component, OnInit} from '@angular/core';
import {Job, JobList, JobsService} from "../jobs.service";
import {Observable, of, map, catchError, throwError} from "rxjs";
import {HttpErrorResponse} from "@angular/common/http";


@Component({
    selector: 'app-joblist',
    templateUrl: './joblist.component.html',
    styleUrls: ['./joblist.component.scss']
})
export class JoblistComponent implements OnInit {
    protected jobList!: Job[];
    protected nextPage!: string;
    protected loadMoreDisabled: boolean = true;

    constructor(private jobsService: JobsService) {
    }

    ngOnInit(): void {
        this.jobsService.getJobList()
            .subscribe(
                jobsList => {
                    this.jobList = [];
                    this.updatePageData(jobsList);
                },
                err => alert('Error on fetching Job list: ' + err)
            )
    }

    loadMoreResults() {
        this.jobsService.getJobList(this.nextPage)
            .subscribe(
                jobsList => this.updatePageData(jobsList),
                err => alert('Error on fetching Job list: ' + err)
            )
    }

    private updatePageData(jobsList: JobList) {
        this.jobList = this.jobList.concat(jobsList.results);
        this.nextPage = jobsList.next;
        this.loadMoreDisabled = jobsList.next == null

    }
}
