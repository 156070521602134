<div
  class="calltoaction {{this.position}}"
  [class.mobile] = "this.onPhone"
  #calltoaction>
  <a
    mat-raised-button
    color="{{this.color}}"
    href="mailto:color33@spatial-services.com?subject={{this.subject}}">
    {{this.label}}
  </a>
</div>