
<app-resources-wrapper context="contact">
  <app-resources-page-title
    [icon]="'contact_mail'"
    [title]="'Contact'">
  </app-resources-page-title>

  <p>
    Interested in learning more? We appreciate your feedback at this early stage of developing color33. Do not hesitate to contact us. If you are interested seeing how spectral categories generated by color33 are already being used, we'd love to share some existing information-generation workflows and on-top applications!
  </p>

  <div class="image-container">
    <img src="/assets/contact/contact.png" />
  </div>

  <p>Let's keep it simple. A friendly contact point:</p>

  <div class="contact">
    Markus Kerschbaumer <br />
    Spatial Services GmbH <br />
    Schillerstraße 30 <br />
    5020 Salzburg <br />
  </div>

  <app-call-to-action
    [position]="'inline'"
    [label]="'Request Demo'"
    [color]="'accent'"
    [subject]="'Color33%20demo%20request'">
  </app-call-to-action>
</app-resources-wrapper>