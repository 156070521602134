import {Component, Input, ViewEncapsulation,} from '@angular/core';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';

@Component({
  selector: 'app-status-bar',
  templateUrl: './status-bar.component.html',
  styleUrls: ['./status-bar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {
      displayDefaultIndicatorType: false
    },
  }]
})

export class StatusBarComponent {
    @Input() createdTimestamp!: Date;
    @Input() startedTimestamp!: Date;
    @Input() finishedTimestamp!: Date;

    constructor() {
        this.createdTimestamp = new Date()
    }
}
