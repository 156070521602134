<div class="{{this.size}}">
  <div class="help-starters">
    <app-resources-page-menu-starter
      [size]="this.size"
      [selected]="this.context === 'gettingstarted' ? 'selected' : ''"
      [text]="'Getting Started'"
      [icon]="'rocket_launch'"
      [link]="'/resources/getting-started'">
    </app-resources-page-menu-starter>

    <app-resources-page-menu-starter
      [size]="size"
      [selected]="this.context === 'manual' ? 'selected' : ''"
      [text]="'Manual'"
      [icon]="'menu_book'"
      [link]="'/resources/manual'">
    </app-resources-page-menu-starter>

    <app-resources-page-menu-starter
      [size]="size"
      [selected]="this.context === 'whatsnew' ? 'selected' : ''"
      [text]="'What\'s new?'"
      [icon]="'list_alt_add'"
      [link]="'/resources/whats-new'">
    </app-resources-page-menu-starter>

    <app-resources-page-menu-starter
      [size]="size"
      [selected]="this.context === 'pricing' ? 'selected' : ''"
      [text]="'Pricing'"
      [icon]="'payments'"
      [link]="'/resources/pricing/'">
    </app-resources-page-menu-starter>

    <app-resources-page-menu-starter
      [size]="size"
      [selected]="this.context === 'contact' ? 'selected' : ''"
      [text]="'Contact'"
      [icon]="'contact_mail'"
      [link]="'/resources/contact/'">
    </app-resources-page-menu-starter>

  </div>

  <ng-template [ngIf]="this.size ==='small'">
    <hr class="separator" />
  </ng-template>
</div>