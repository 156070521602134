import { Component, OnInit } from '@angular/core';
import { Result } from './result/result.component';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit{
  protected resultList!: Result[];

  result1: Result = {
    name: 'S2AXXXXXXX.SAFE',
    completed: false,
    files: [
      {name: 'Color33', completed: false},
      {name: 'Greenness', completed: false},
      {name: 'Haze', completed: false},
    ],
  }

  result2: Result = {
    name: 'S2AXXXXXXX.SAFE',
    completed: false,
    files: [
      {name: 'Color33', completed: false},
      {name: 'Greenness', completed: false},
      {name: 'Haze', completed: false},
    ],
  }

  ngOnInit():void {
    this.resultList = [
      this.result1,
      this.result2
    ];
  }
}
