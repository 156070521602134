import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-login-redirect-component',
  templateUrl: './login-redirect.component.html',
  styleUrls: ['./login-redirect.component.scss']
})
export class LoginRedirectComponent implements OnInit {

  constructor(private oidcSecurityService: OidcSecurityService ) {
  }
  ngOnInit() {
    this.oidcSecurityService.authorize();
  }
}
