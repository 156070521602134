import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router';
import {ViewChild} from '@angular/core';
import {MapViewComponent} from './map-view/map-view.component';
import {ConfirmDialogComponent} from 'src/app/dialog/confirm/confirm-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {Job, JobInputs, JobResults, JobsService} from "../jobs.service";
import {switchMap} from "rxjs";
import {HttpClient} from "@angular/common/http";
import {FileSaverService} from "ngx-filesaver";
import {OidcSecurityService} from "angular-auth-oidc-client";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, catchError } from 'rxjs/operators';

import { NotificationDialogComponent } from '../dialog/notification/notification-dialog.component';
import { jobLabel } from './labels-view/labels-view.component';
import { ProcessingOptionsComponent } from './processing-options/processing-options.component';

@Component({
    selector: 'app-job',
    templateUrl: './job.component.html',
    styleUrls: ['./job.component.scss'],
})
export class JobComponent implements OnInit, AfterViewInit {

    @ViewChild(MapViewComponent) map !: any;
    @ViewChild(ProcessingOptionsComponent) processingOptions!: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private jobsService: JobsService,
        public dialog: MatDialog,
        private http: HttpClient,
        private fileSaverService: FileSaverService,
        private oidcSecurityService: OidcSecurityService,
    ) {
    }

  /*
   * These are the default processing options
   */
    defaultProcessingOptions = {
        "epsgCode": null,
        "outputLayer": ["Siam33"],
        "cloudCover": {
          "min": 0,
          "max": 100
        },
        "provideOriginalSatelliteImages": null,
        "provideCloudOptimisedOutput": true,
      };

    /*
     * These variables are used for the granule search. It is the form control,
     * the filtered results from the server, and then the options that are
     * actually presented to the user in the dropdown list.
     */
    filteredGranulesForSearch!: Observable<string[]>;
    options: string[] = [];
    granuleSearch = new FormControl();

    /*
     * This is the function that takes a search-string and performs a search
     * in the WFS to find a granule.
     * Note: The search is triggered for each change in the input field. This
     * could perhaps create a lot of traffic. Since we assume that the search
     * field is not used often, this is okay.
     */
    private _search(searchString: string): string[] {

        let candidates: string[] = [];

        /*
         * We start searching after the second character to reduce the load and
         * traffic.
         */
        if (searchString.length >= 2){

            /*
             * Creating the search string
             */
            let granulesURL = this.map.granulesWFSUrl;
            granulesURL += '&FILTER=<Filter>'
            granulesURL += '  <PropertyIsLike wildCard="?" singleChar="_" escape="\\"  matchCase="false">'
            granulesURL += '    <PropertyName>Name</PropertyName>'
            granulesURL += '    <Literal>' + searchString + '?</Literal>'
            granulesURL += '  </PropertyIsLike>'
            granulesURL += ' </Filter>';

            /*
             * Make the http request and obtain the results as GeoJSON
             */
            this.http.get<any>(granulesURL).pipe(
                catchError((error) => {
                  alert("There was a problem fetching the data");
                  throw error;
                })
              ).subscribe((geojson) => {
                geojson.features.forEach((feature: any) => {candidates.push(feature.properties["Name"])});
                /*
                 * We use this trick to shorten the coding a bit. We assume that
                 * if there is only one result, it is the target and we pan
                 * to this.
                 */
                if (geojson.features.length === 1){
                    this.map.panToFeatureExtent(geojson.features);
                }
                return candidates;
            });
        }else{
            return candidates;
        }
        return candidates;
    }


    filenameInputValue: String = '';
    jobId: string = "";

    job!: Job;
    jobResults!: JobResults;

    jobLabels: jobLabel[] = [];

    newJobStartDate: Date = new Date();
    newJobEndDate: Date = new Date();
    newJobStartDateSet: boolean = false;
    newJobEndDateSet: boolean = false;

    newJobGranules: string[] = [];

    jobSettingsComplete: boolean = false;

    customProcessingOptions: boolean = false;

    ngOnInit(): void {

        this.jobId = this.route.snapshot.paramMap.get('jobid') || "";
        if (this.jobId) {
            this.jobsService.getJob(this.jobId)
                .subscribe((job) => {
                    this.job = job;
                    this.jobLabels.push(<jobLabel>{
                        key: this.job.status,
                        tooltip: "This is the last status of the job",
                        color: this.job.status
                    });
                    this.jobLabels.push(<jobLabel>{
                        key: this.job.inputs.dry_run? "dry-run" : "wet-run",
                        tooltip: this.job.inputs.dry_run? "Repeat this job as wet-run to get results" : "This job produced results",
                        color: "orange"
                    });
                })
            this.jobsService.getJobResults(this.jobId)
                .subscribe((jobResults) => this.jobResults = jobResults)
        } else {
            this.filteredGranulesForSearch = this.granuleSearch.valueChanges.pipe(
                startWith(''),
                map(searchString => this._search(searchString || '')),
            );
            this.jobLabels.push(<jobLabel>{
                key: "New job",
                tooltip: "This is a new job. New jobs must be submitted as dry-run first. It will get an identifier assigned once it is submitted. ",
                color: "orange"
            });
            this.toggleCompleteLabel("incomplete");
            if (this.map) {
                this.map.destroyMap();
            }
        }
    };

    ngAfterViewInit(): void {
        /*
         * If we don't enter the route where the jobId is set,
         * it means there will a new job be defined. Reset the map
         * to remove any previous stuff.
         */
        // if (!this.job || !this.job.jobID) {
        //     this.map.destroyMap();
        // }
    }

    onObjectUpdated(updatedObject: any) {
        console.log('Parent received updated object:', updatedObject);
    }

    validate(): void {
        if (this.isComplete() === false){
            this.dialog.open(NotificationDialogComponent, {
                width: '250px',
                enterAnimationDuration: "200ms",
                exitAnimationDuration: "200ms",
                data: {
                    message: "Please select at least one granule and a date range.",
                    thisPtr: this
                },
            });
            return;
        }

        /*
         * Get the processing options, either the default one or custom one.
         */
        const newProcessingOptions = (() => {
            if (this.customProcessingOptions){
                return this.processingOptions.getProcessingOptions();
            } else {
                return this.defaultProcessingOptions;
            }
        })();

        /*
         * Assemble the job inputs
         */
        const jobInputs: JobInputs = {
            dry_run: true,
            scenes: this.newJobGranules,
            from_date: this.newJobStartDate,
            to_date: this.newJobEndDate,
            epsg_code: newProcessingOptions.epsgCode,
            output_layers: newProcessingOptions.outputLayer,
            cloud_cover_min: newProcessingOptions.cloudCover.min,
            cloud_cover_max: newProcessingOptions.cloudCover.max,
            provide_original_satellite_images: newProcessingOptions.provideOriginalSatelliteImages,
            provide_cloud_optimised_output: newProcessingOptions.provideCloudOptimisedOutput,
        };

        this.jobsService.executeJob(jobInputs)
            .subscribe((job) => {
                this.dialog.open(NotificationDialogComponent, {
                    width: '250px',
                    enterAnimationDuration: "200ms",
                    exitAnimationDuration: "200ms",
                    data: {
                        message: "Job started successfully.",
                        thisPtr: this
                    },
                });
                this.router.navigate(['job', job.jobID]);
            });
    };

    private toggleCompleteLabel(label: String): void {

        /*
         * If the label is `complete`, search if we have a label already or not.
         * If not, add one. If yes, skip.
         * In both options, remove the label `incomplete`. We search the position
         * of the label and remove one element.
         */
        if (label === "complete"){
            if (this.jobLabels.findIndex(obj => obj.key === "Complete") === -1){
                this.jobLabels.push(<jobLabel>{
                    key: "Complete",
                    tooltip: "All set! ",
                    color: "green"
                });
            }
            let incompleteLabelPosition = this.jobLabels.findIndex(obj => obj.key === "Incomplete");
            if (incompleteLabelPosition !== -1){
                this.jobLabels.splice(incompleteLabelPosition, 1);
            }
        }
        if (label === "incomplete"){
            if (this.jobLabels.findIndex(obj => obj.key === "Complete") === -1){
                this.jobLabels.push(<jobLabel>{
                    key: "Incomplete",
                    tooltip: "Some settings are missing. ",
                    color: "red"
                });
            }
            let completeLabelPosition = this.jobLabels.findIndex(obj => obj.key === "Complete");
            if (completeLabelPosition !== -1){
                this.jobLabels.splice(completeLabelPosition, 1);
            }
        }
    }

    toggleProjectionsLabel(label: String): void {

        /*
         * Check if the label is already in the list.
         */
        let incompleteLabelPosition = this.jobLabels.findIndex(obj => obj.key === "Different projections");
        let chipIsVisible = incompleteLabelPosition !== -1;

        /*
         * If the label is in the list, remove it if the label is `same` or the hint is to keep it.
         * Keeping essentially means it may be replaced.
         */
        if (chipIsVisible && (label === "same" || label === "keep")){
            this.jobLabels.splice(incompleteLabelPosition, 1);
        }
        /*
         * If the label is not yet it the list but the projection is different, add it. If the label is visible and
         * hint is to keep it, we add it as well (essentially potentially replace it).
        */
        if ((!chipIsVisible && label === "different") || (chipIsVisible && label === "keep")){
            /*
                * Check if the user has selected a custom EPSG code. If yes, we show the label as a warning in orange. Otherwise, we show it as an alert in red.
            */
            let hasCustomEPSGCode = false;
            if (this.processingOptions){
                hasCustomEPSGCode = this.processingOptions.hasCustomEPSGCode();
            }
            if (hasCustomEPSGCode){
                this.jobLabels.push(<jobLabel> {
                    key: "Different projections",
                    tooltip: "You selected different granules with potentially different projections. All images will be re-projected to the EPSG code you selected.",
                    color: "green"
                });
            } else {
                this.jobLabels.push(<jobLabel>{
                    key: "Different projections",
                    tooltip: "You selected different granules with potentially different projections. Consider re-projecting them if you want a consistent projection. You can use the processing options to set the projection.",
                    color: "red"
                });
            }

        }
    }

    isComplete(): boolean {
        if (this.newJobGranules.length === 0){
            return false;
        }

        if (this.newJobStartDateSet === false) {
            return false;
        }

        if (this.newJobEndDateSet === false) {
            return false;
        }

        this.toggleCompleteLabel("complete");

        return true;
    }

    confirmDeleteMessage: string = "Do you want to continue deleting the job? This can not be reversed.";


    delete(that: any): void {
        //TODO(SR) as this is called from the ConfirmDialog we can't use "this" here. So we do this little "that" dance.
        that.jobsService
            .deleteJob(that.jobId)
            .subscribe((result: any)=>{
                alert("Job deleted sucessfully");
                that.router.navigate(['jobs']);
                })
    }

    openDeleteDialog(enterAnimationDuration: string, exitAnimationDuration: string, message: string): void {
        this.dialog.open(ConfirmDialogComponent, {
            width: '250px',
            enterAnimationDuration,
            exitAnimationDuration,
            data: {
                message: message,
                thisPtr: this,
                confirmAction: this.delete,
            },
        });
    }

    isDownloadDisabled(): boolean {
        return (!this.jobResults ||
        !this.jobResults.siam_results ||
        Object.keys(this.jobResults.siam_results).length == 0)
    }

    downloadZip() {
        this.oidcSecurityService.getAccessToken()
            .subscribe((token) => {
                window.open(`${this.jobsService.JOBS_URL}/${this.jobId}/results/zip?token=${token}`, "_blank");
            });
    }
    downloadUrl(url: string) {
        this.oidcSecurityService.getAccessToken()
            .subscribe((token) => {
                window.open(`${url}?token=${token}`, "_blank");
            });
    }

    runJob(event: Event, asDryRun: boolean) {
        const modifiedInputs = structuredClone(this.job.inputs);
        modifiedInputs.dry_run = asDryRun;
        this.jobsService.executeJob(modifiedInputs)
            .subscribe((result: Job) => {
                alert("Job started sucessfully.");
                this.router.navigate(['job', result.jobID]);
            })
    }

    private hasDifferentProjections(granules: string[]): boolean {

        if (granules.length === 0) return false; // If the array is empty, return false

        // Extract the first two digits and the first letter of the first element
        const firstZone = granules[0].substring(0, 2);
        const firstRowHemishpere = findHemisphere(granules[0].charAt(2));

        // Function to check if a character is before or after 'M' in the alphabet
        function findHemisphere(char: string) {
            return char > 'M'? 'N' : 'S';
        }

        // Return true if all granules are in the same zone and hemisphere
        return granules.every(item => item.substring(0, 2) === firstZone) &&
                granules.every(item => findHemisphere(item.charAt(2)) === firstRowHemishpere);
    }

    selectedGranulesChanged(event: string[]) {
        this.newJobGranules = event;
        this.hasDifferentProjections(this.newJobGranules)? this.toggleProjectionsLabel("same") : this.toggleProjectionsLabel("different");
        this.jobSettingsComplete = this.isComplete();
    }

    selectedStartDateChange(date: Date){
        this.newJobStartDate = new Date(date.setHours(0,0,0,0));
        this.newJobStartDateSet = true;
        this.jobSettingsComplete = this.isComplete();
    }

    selectedEndDateChange(date: Date){
        this.newJobEndDate = new Date(date.setHours(23,59,59,9999));
        this.newJobEndDateSet = true;
        this.jobSettingsComplete = this.isComplete();

    }

    saveFile(url: string, fileName: string) {
        return this.oidcSecurityService.getAccessToken()
            .pipe(
                switchMap((token: string) => {
                    return this.http.get(url,
                        { headers: {'Authorization': 'Bearer ' + token},
                                 responseType: 'blob' }
                    )
                })
            )
            .subscribe((res: Blob) => {
                console.log('Download:res', res)
                this.fileSaverService.save((<any>res), fileName);
        });
    }

    toggleProcessingOptions(){
        this.customProcessingOptions = !this.customProcessingOptions;
        if (!this.customProcessingOptions){
            this.processingOptions.resetProcessingOptions();
        }
        this.toggleProjectionsLabel("keep");
    }

    protected readonly Object = Object; //TODO(SR) this works, but kinda stinks. Rework this.
}
