<div>
  <div class="option">
    <div class="icon">
      <img class="image" src="assets/processing-options/epsg-code.png" />
    </div>
    <div class="content">
      <h3>
        <span>EPSG Code / Projection</span>
      </h3>
      <p class="description">Use this to specify the EPSG code (projection) for the image. If nothing is set, the original projection will be used, e.g., UTM for Sentinel-2. The re-projection will be applied on the original values prior to the categorisation. You may use https://epsg.io for searching the appropriate EPSG code for your geographic area or application. If you don't need a specific projection, keep this as default for faster results.</p>
      <div class="option-value">
        <mat-form-field class="granule-search">
          <mat-label>{{this.EPSGCodeLabel}}</mat-label>
          <input
            [matTooltip]="this.readOnly? 'EPSG code of the images' : 'Start typing the EPSG code'"
            matInput
            [matAutocomplete]="auto"
            [formControl]="EPSGCodeSearch"
            [(ngModel)]="this.processingOptions.epsgCode"
            [disabled]="this.readOnly"
            [readonly]="this.readOnly"
          >
            <mat-autocomplete
              #auto="matAutocomplete"
              (optionSelected)="setEPSGCodeProcessingOption($event.option)"
            >
              <mat-option *ngFor="let option of filteredEPSGForSearch | async" [value]="option">
                {{ option }}
              </mat-option>
            </mat-autocomplete>
            <button
                *ngIf="!this.readOnly"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearEPSGCode()"
              >
              <mat-icon>close</mat-icon>
            </button>

            <div *ngIf="this.readOnly" class="overlay"></div>
        </mat-form-field>
      </div>
      <div class="more-information">{{this.EPSGCodeWKT}}</div>
    </div>
  </div>


  <div class="option">
    <div class="icon">
      <img class="image" src="assets/processing-options/cloud-cover.png" />
    </div>
    <div class="content">
      <h3>
        <span>Cloud cover</span>
      </h3>
      <p class="description">Use this to specify the minimum and maximum cloud cover. It will be used to search within the archive. Note that this applies for the entire image based on the metadata provided by the data provider. It will not use the cloud-like labels of color33. Currently, only the maximum cloud cover can be selected.</p>
      <div class="option-value">
        <span class="label-left"><p>{{this.processingOptions.cloudCover.min}}%</p></span>
        <mat-slider  min="0" max="100" step="1" showTickMarks discrete class="interaction cloudcover" >
          <input
            value="{{this.processingOptions.cloudCover.min}}"
            matSliderStartThumb
            (valueChange)="setCloudCoverFilterProcessingOption($event, 'cloudCoverMin')"
            [ngStyle]="{ 'pointer-events': 'none' }"
          >
          <input
            value="{{this.processingOptions.cloudCover.max}}"
            matSliderEndThumb
            (valueChange)="setCloudCoverFilterProcessingOption($event, 'cloudCoverMax')"
            [ngStyle]="{ 'pointer-events': this.readOnly ? 'none' : 'auto' }"
          >
        </mat-slider>
        <span class="label-right"><p>{{this.processingOptions.cloudCover.max}}%</p></span>
      </div>
    </div>
  </div>


  <div class="option">
    <div class="icon">
      <img class="image" src="assets/processing-options/processing-outputs.png" />
    </div>
    <div class="content">
      <h3>
        <span>Processing outputs</span>
      </h3>
      <p class="description">Choose more granular processing ouputs here. At least one need to be selected and if nothing is selected the option with 33 color names will be active and remain.</p>
      <div class="option-value">
        <section class="processing-ouputs" [formGroup]="processingOutputs">
          <p *ngFor="let output of processingOutputsDetail">
            <mat-checkbox
              formControlName="{{output.key}}"
              aria-label="{{output.label}}"
              matTooltip="{{output.description}}"
              color="primary"
              (click)="this.readOnly ? disabledInput($event) : setOutputLayersProcessingOption()"
            >
            {{output.label}}
            </mat-checkbox>
          </p>
        </section>
      </div>
    </div>
  </div>

  <div class="option">
    <div class="icon">
      <img class="image" src="assets/processing-options/original-images.png" />
    </div>
      <div class="content">
      <h3>
        <span>Provide original satellite images</span>
      </h3>
      <p class="description">Check this if you want the original satellite images as well. If you don't need it, keep it un-checked for faster results.</p>
      <div class="option-value">
        <section class="processing-ouputs" [formGroup]="provideOriginalSatelliteImages">
          <p>
            <mat-checkbox
              formControlName="standard"
              color="primary"
              (click)="this.readOnly ? disabledInput($event) : setOriginalSatelliteImageOutputProcessingOption('standard')"
            >
              Provide the original image in the standard format
            </mat-checkbox>
          </p>
          <p>
            <mat-checkbox
              formControlName="cloudOptimised"
              color="primary"
              (click)="this.readOnly ? disabledInput($event) : setOriginalSatelliteImageOutputProcessingOption('cloudOptimised')"
            >
              Provide the original image in a cloud-optimised format
            </mat-checkbox>
          </p>
        </section>
      </div>
    </div>
  </div>

  <div class="option">
    <div class="icon">
      <img class="image" src="assets/processing-options/cloud-optimised.png" />
    </div>
      <div class="content">
      <h3>
        <span>Provide cloud-optimised formats</span>
      </h3>
      <p class="description">Check this if you want the outputs as cloud-optimised format.</p>
      <div class="option-value">
        <section class="processing-ouputs" [formGroup]="provideCloudOptimisedOutput">
          <p>
              <mat-checkbox
                formControlName="cloudOptimised"
                color="primary"
                (click)="this.readOnly ? disabledInput($event) : setCloudOptimisedOutputProcessingOption()"
              >
                Provide the output in a cloud-optimised format
              </mat-checkbox>
            </p>
        </section>
      </div>
    </div>
  </div>

</div>