export const environment = {
    production: true,
    AUTH: {
        AUTHORITY: "https://auth.color33.io/realms/siamaas",
        BASE_URL: "https://auth.color33.io/realms/siamaas/protocol/openid-connect",
        WELLKNOWN_ENDPOINT_URL: "/assets/"
    },
    API: {
        LOGIN_URL: "https://next-api.color33.io/accounts/keycloak/login/",
        BASE_URL: "https://next-api.color33.io/ogc-api",
    }
};
