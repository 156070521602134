<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer
      [ngClass]="this.navigationCollapsed ? 'sidenav sidenav-collapsed' : 'sidenav sidenav-full'"
      fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false">
    <!--<mat-toolbar>Menu</mat-toolbar>-->
    <mat-nav-list class="main-actions">

      <app-nav-button
        [label]="'Home'"
        [tooltipMessage]="'Show landing page'"
        [icon]="'home'"
        [small]="this.navigationCollapsed"
        [link]="'/'">
      </app-nav-button>

      <app-nav-button
        [label]="'New Job'"
        [tooltipMessage]="'Create & submit a new job'"
        [icon]="'add-box'"
        [small]="this.navigationCollapsed"
        [link]="'/new'"
        [disabled]="!isAuthenticated">
      </app-nav-button>

      <app-nav-button
        [label]="'Show Jobs'"
        [tooltipMessage]="'List your jobs'"
        [icon]="'view_timeline'"
        [small]="this.navigationCollapsed"
        [link]="'/jobs'"
        [disabled]="!isAuthenticated">
      </app-nav-button>

      <!--
      <app-nav-button
        [label]="'Settings'"
        [tooltipMessage]="'Configure SIAM-as-a-Service at the Settings page'"
        [icon]="'tune'"
        [small]="this.navigationCollapsed"
        [link]="'/settings'"
        [disabled]="!isAuthenticated">
      </app-nav-button>
-->

      <app-nav-button
        [label]="'Resources'"
        [tooltipMessage]="'Show additional resources such as manual, contact, pricing etc.'"
        [icon]="'help'"
        [small]="this.navigationCollapsed"
        [link]="'/resources'">
      </app-nav-button>

    </mat-nav-list>

    <div *ngIf="!this.isPhonePortrait" class="collapse" (click)="collapse()">
      <div class="collapse-button-wrapper">
        <mat-icon class="clickable" fontIcon="arrow_forward" *ngIf="this.navigationCollapsed"></mat-icon>
        <mat-icon class="clickable" fontIcon="arrow_back" *ngIf="!this.navigationCollapsed"></mat-icon>
      </div>
    </div>

  </mat-sidenav>
  <mat-sidenav-content
    class="content-wrapper"
    [class.collapsed-phone] = "this.collapsed && this.isPhonePortrait"
    [class.full-phone] = "!this.collapsed && this.isPhonePortrait"
    [class.collapsed-web] = "this.collapsed && !this.isPhonePortrait"
    [class.full-web] = "!this.collapsed && !this.isPhonePortrait"
  >

    <mat-toolbar class="header" color="primary">
      <span
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </span>
      <a
      [class.center] = "this.isPhonePortrait"
      routerLink="/"
      href="/">
        <span
          class="logo"
          aria-label="Back to landingpage"
          matTooltip="Back to landingpage">
            <img src="/assets/logo.png"/>
            color33
        </span>
      </a>
      <span class="user-actions">
        <div class="icon clickable"  *ngIf="!isAuthenticated">
            <span [innerHTML]="loginForm"></span>
<!--          <mat-icon aria-hidden="false" aria-label="Login" matTooltip="Login" fontIcon="login" (click)="login()"></mat-icon> -->
        </div>
        <div class="icon clickable"  *ngIf="isAuthenticated">
          <mat-icon aria-hidden="false" aria-label="Logout" matTooltip="Logout" fontIcon="logout" (click)="logout()"></mat-icon>
        </div>
        <div class="icon clickable" routerLink="me" href="/me" *ngIf="isAuthenticated">
          <mat-icon aria-hidden="false" aria-label="User Info" fontIcon="person"></mat-icon>
        </div>
      </span>
      <span class="user-usage" *ngIf="!isAuthenticated">
        <div class="clickable" routerLink="/resources/getting-started" href="/resources/getting-started">
          <span
            aria-hidden="false"
            aria-label="Features"
            matTooltip="Leads you to an overview over the features">
              Features
          </span>
        </div>
        <div class="clickable" routerLink="/resources/pricing" href="/resources/pricing">
          <span
            aria-hidden="false"
            aria-label="Pricing"
            matTooltip="Leads you to an overview ofer the price list">
              Pricing
          </span>
        </div>
        <div class="clickable" routerLink="/resources/contact" href="/resources/contact">
          <span
            aria-hidden="false"
            aria-label="Request Demo"
            matTooltip="If you need more information, click here to book a demo session with us">
              Request Demo
          </span>
        </div>
        <div class="separator">
        </div>
      </span>
      <span class="user-info" *ngIf="isAuthenticated">
        <div class="user-name">{{this.username}}</div>
        <div class="user-email">{{this.userEmail}}</div>
      </span>
    </mat-toolbar>
    <!-- Add Content Here -->

    <router-outlet></router-outlet>

  </mat-sidenav-content>
</mat-sidenav-container>
