<a mat-stroked-button color="basic"
   matTooltip="{{tooltipMessage}}"
   [matTooltipPosition]="'right'"
   [disabled]="this.disabled"
   class="menu-item"
   [ngClass]="this.small ? 'small-menu' : 'full-menu'"
   routerLink="{{link}}"
   href="{{link}}">

    <div class="icon" [ngClass]="this.small ? 'small-icon' : 'full-icon'">
      <mat-icon fontIcon="{{icon}}"></mat-icon>
    </div>
    <div class="label" *ngIf="!this.small">{{label}}</div>
</a>
