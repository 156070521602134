import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-resources-page-menu-starter',
  templateUrl: './resources-page-menu-starter.component.html',
  styleUrls: ['./resources-page-menu-starter.component.scss']
})
export class ResourcesPageMenuStarterComponent implements OnInit {
  @Input() link!: string;
  @Input() icon!: string;
  @Input() text!: string;
  @Input() size!: string;
  @Input() selected: string = "";

  tooltipText: string = "";

  ngOnInit(): void {
    if (this.size === "small"){
      this.tooltipText = this.text;
    }
  }
  
}
