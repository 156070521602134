<app-textbox>
    <h1>Security Statement</h1>
    <p>We take the security of your information seriously. We are committed to protecting the confidentiality, integrity, and availability of your data. This Security Statement outlines the measures we take to safeguard your information when you interact with our website.</p>

    <h2>Data Encryption</h2>
    <p>We use industry-standard encryption protocols to protect data transmission between your browser and our servers. This ensures that your personal information remains secure during transmission over the internet.</p>

    <h2>Secure Storage</h2>
    <p>All sensitive data collected through our website is stored in databases with restricted access. Access to this data is restricted to authorized personnel only, and we regularly review our security measures.</p>

    <h2>User Authentication</h2>
    <p>We require authentication mechanisms for accessing sensitive information on our website. This includes the use of passwords to verify the identity of users.</p>

    <h2>Incident Response</h2>
    <p>In the event of a security incident or data breach, we will notify affected individuals and authorities as required by law.</p>

    <h2>Continuous Monitoring</h2>
    <p>We employ continuous monitoring tools and techniques to detect and respond to security threats in real-time. This proactive approach allows us to identify and address potential security issues before they escalate.</p>

    <h2>Compliance</h2>
    <p>We are committed to complying with relevant data protection laws and regulations, including GDPR, CCPA, and others. Our security practices are designed to meet or exceed the requirements outlined in these regulations.</p>

    <h2>Third-party Services</h2>
    <p>When we use third-party services or vendors to process or store data, we carefully vet their security practices to ensure they meet our high standards of security and compliance.</p>

    <h2>Location of color33</h2>
    <p>color33 is exclusively hosted in countries of the European Union.</p>

    <h2>Data privacy</h2>
    <p>We are transparent in how we collect, use, and handle private data when color33 is used. Please see <a href="/legal/privacy">our Privacy Policy</a> for more information.</p>

    <h2>Contact us</h2>
    <p>If you have any questions, concerns, or complaints regarding our security, we encourage you to contact us using the details below:</p>
    <p><a href="mailto:color33@spatial-services.com">color33@spatial-services.com</a></p>

    <p>Last update: 14.03.2024</p>
</app-textbox>