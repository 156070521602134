<div class="wrapper">

  <mat-card class="card">
    <mat-card-content>
      <mat-grid-list cols="3" class="avatar">
        <mat-grid-tile colspan="1">
          <img class="image" src="assets/default-user-female.svg" />
        </mat-grid-tile>
        <mat-grid-tile colspan="2">
          <div class="info">
            <div class="name">{{this.name}}&nbsp;<small>({{this.username}})</small></div>
              <div class="email">{{this.email}}</div>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    </mat-card-content>
  </mat-card>

  <!--
  <mat-card class="card notification-panel" matBadge="3" matBadgeSize="large" matBadgePosition="before" matBadgeColor="warn">
    <mat-card-content>
      <h1>Notifications</h1>

      <mat-accordion>
        <app-notification [status]="'succeeded'"></app-notification>
        <app-notification [status]="'succeeded'"></app-notification>
        <app-notification [status]="'succeeded'"></app-notification>
      </mat-accordion>
      
    </mat-card-content>
  </mat-card>
  -->
</div>