<mat-card class="manual-note mat-accent">
  <h3 class="title">
    <span class="material-symbols-outlined">
      info
    </span>
    Note
  </h3>
  <div class="content">
    <ng-content></ng-content>
  </div>
</mat-card>