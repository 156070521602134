<app-resources-wrapper context="gettingstarted">
  <app-resources-page-title
    [icon]="'rocket_launch'"
    [title]="'Getting started'">
  </app-resources-page-title>

  <app-resources-page-section title="Overview and demo package">
    <p>
      Jump directly to some demo datasets using this
      <a href="https://www.spatial-services.com/spatialservices/wp-content/uploads/2024/04/color33-Demo-Package.zip" target="_blank" class="internal-link">
        <img src="/assets/getting-started/demo-package-icon.png" alt="Brochure Icon" class="internal-link-icon">demo package</a> (283 Mb). It consists of several semantically enriched Sentinel-2 images, which you can download for free and directly use in your software (e.g. QGIS or ArcGIS).
    </p>
    <p>
      Want to get an overview?  Download our 
      <a href="/assets/getting-started/color33-brochure-en.pdf" target="_blank" class="internal-link">
        <img src="/assets/brochure-icon.png" alt="Brochure Icon" class="internal-link-icon">two-page brochure</a> (2.8 Mb).
    </p>

  </app-resources-page-section>

  <app-resources-page-section title="What is semantic enrichment?">

    <p>Semantic enrichment in the EO imagery domain refers to interpreted content of EO imagery (i.e., mapping data to symbols that represent stable concepts). In the case of EO imagery represented in a raster (i.e., image), this means mapping each raster cell (i.e., pixel) to an interpretation that represents a stable concept. These concepts are generally non-ordinal, categorical variables. However, subsets of these variables may be ordinal (e.g., vegetation categorised by increasing greenness or intensity). The relative level of semantic enrichment can vary in complexity and the “symbolic” level of the concepts/variables. Concepts represented by a relatively lower level of semantic enrichment could be considered semi-symbolic. These concepts are a first step towards connecting sensory data to stable, symbolic, semantic classes such as land cover classes. Semantically enriched EO imagery time-series can be used to create semantically-enabled EO data cubes (i.e., semantic EO data cubes).</p>

    <app-resources-page-image file="/assets/siam-technology/siam_overview_1.png">
      Schematic overview over color33's semantic enrichment process. A multi-spectral image is categorised into a set of categories using a knowledge-based, phyiscal-model-based decision tree. It is neither a black-box nor a clustering approach. The categories are not yet land cover units but can be integrated into land cover units using additional rules, e.g., using a time-series.
    </app-resources-page-image>

    <app-resources-page-note>
      Semantically enriched images are not yet land cover maps. They are a first step towards creating land cover maps. Instead, they are a generic layer applicable <b>worldwide</b> and for <b>different sensors</b> from which land cover maps can be created using additional rules. These rules could be occurrences of categories at a certain time or for a certain duration. Alternatively, machine-learning-based approaches can be used as well.
      <br/>
      <br/>
      <b>The main advantage is the reduction of data size, and the transferability across geographical regions and sensors. </b>See the example section below to get an idea of how to use semantically enriched images.
    </app-resources-page-note>

    <p>Let's look at all Sentinel-2 imagery spanning from 9 August 2019 until 24 June 2020. Processed with color33, a few of these individual images look like the following:</p>

    <img src="/assets/getting-started/workflow_basis.png" />

    <p>Instead of being faced with a bunch of reflectance values, you can start working directly with each pixel based on the color it has been assigned, which has a semantic association:</p>

    <app-resources-page-image file="/assets/getting-started/water_count.png">
      The color legend of color33's categories associated with water.
    </app-resources-page-image>

    <app-resources-page-image file="/assets/getting-started/vegetation_count.png">
      The color legend of color33's categories associated with vegetation.
    </app-resources-page-image>

    <p>However, while the colors (i.e. spectral categories) are actionable, generally there is no perfect and exclusive match between a semantic concept (e.g. water) and any of the colors. As with any other imagery analysis, some pixels representing different semantic concepts (e.g. water and deep shadow) have very similar multi-spectral profiles, so require additional information to be reliably distinguished from each other in analysis. For more advanced analysis, users can use the color categories to segment or stratify further analyses. For example, instead of calculating the NDVI across an entire image to then identify vegetation, the colors enable users to only calculate the index on pixels that look like vegetation based on their color to remove or minimise the need for thresholding to extract meaningful information about vegetation.</p>

    <app-resources-page-image file="/assets/getting-started/ndvi_green.png">
      NDVI calculated only on pixels that look like vegetation. These categories are used as a mask.
    </app-resources-page-image>

    <p>The following table shows the 33 spectral categories, which are available in the default output</p>

    <table class="table">
      <thead>
        <tr>
          <th>Code</th>
          <th>Abbreviation</th>
          <th>Description of the spectral category (not land cover)</th>
          <th>Color</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>1</td>
          <td>SVHNIR</td>
          <td>Strong vegetation with high NIR</td>
          <td style="background-color:#1efa1e;"></td>
        </tr>
        <tr>
          <td>2</td>
          <td>SVLNIR</td>
          <td>Strong vegetation with low NIR</td>
          <td style="background-color:#14af14;"></td>
        </tr>
        <tr>
          <td>3</td>
          <td>AVHNIR</td>
          <td>Average vegetation with high NIR</td>
          <td style="background-color:#00dc5a;"></td>
        </tr>
        <tr>
          <td>4</td>
          <td>AVLNIR</td>
          <td>Average vegetation with low NIR</td>
          <td style="background-color:#00aa6e;"></td>
        </tr>
        <tr>
          <td>5</td>
          <td>WV</td>
          <td>Weak vegetation</td>
          <td style="background-color:#affa8c;"></td>
        </tr>
        <tr>
          <td>6</td>
          <td>SHV</td>
          <td>Shadow area with vegetation</td>
          <td style="background-color:#647850;"></td>
        </tr>
        <tr>
          <td>7</td>
          <td>SHRBRHNIR</td>
          <td>Shrub Rangeland with High NIR</td>
          <td style="background-color:#befaa0;"></td>
        </tr>
        <tr>
          <td>8</td>
          <td>SHRBRLNIR</td>
          <td>Shrub Rangeland with Low NIR</td>
          <td style="background-color:#8caa6e;"></td>
        </tr>
        <tr>
          <td>9</td>
          <td>HRBCR</td>
          <td>Herbaceous Rangeland</td>
          <td style="background-color:#e1fa32;"></td>
        </tr>
        <tr>
          <td>10</td>
          <td>WR</td>
          <td>Weak Rangeland</td>
          <td style="background-color:#c8c864;"></td>
        </tr>
        <tr>
          <td>11</td>
          <td>PB</td>
          <td>Pit or bog</td>
          <td style="background-color:#648278;"></td>
        </tr>
        <tr>
          <td>12</td>
          <td>GH</td>
          <td>Greenhouse</td>
          <td style="background-color:#afffff;"></td>
        </tr>
        <tr>
          <td>13</td>
          <td>VBBB</td>
          <td>Very bright barren land or built-up</td>
          <td style="background-color:#ffaa6e;"></td>
        </tr>
        <tr>
          <td>14</td>
          <td>BBB</td>
          <td>Bright barren land or built-up</td>
          <td style="background-color:#e69b5f;"></td>
        </tr>
        <tr>
          <td>15</td>
          <td>SBB</td>
          <td>Strong barren land or built-up</td>
          <td style="background-color:#d28c64;"></td>
        </tr>
        <tr>
          <td>16</td>
          <td>ABB</td>
          <td>Average barren land or built-up</td>
          <td style="background-color:#b46446;"></td>
        </tr>
        <tr>
          <td>17</td>
          <td>DBB</td>
          <td>Dark barren land or built-up</td>
          <td style="background-color:#8c3c32;"></td>
        </tr>
        <tr>
          <td>18</td>
          <td>WBBorSHB</td>
          <td>Weak barren land or Shadow area with Barren Land</td>
          <td style="background-color:#e6823c;"></td>
        </tr>
        <tr>
          <td>19</td>
          <td>NIRPBB</td>
          <td>Near InfraRed-Peaked Barren land or Built-up</td>
          <td style="background-color:#bebedc;"></td>
        </tr>
        <tr>
          <td>20</td>
          <td>BA</td>
          <td>Burned area</td>
          <td style="background-color:#823250;"></td>
        </tr>
        <tr>
          <td>21</td>
          <td>DPWASH</td>
          <td>Deep water or shadow</td>
          <td style="background-color:#000050;"></td>
        </tr>
        <tr>
          <td>22</td>
          <td>SLWASH</td>
          <td>Shallow water or shadow</td>
          <td style="background-color:#00286e;"></td>
        </tr>
        <tr>
          <td>23</td>
          <td>TWASH</td>
          <td>Turbid water or shadow</td>
          <td style="background-color:#466482;"></td>
        </tr>
        <tr>
          <td>24</td>
          <td>SASLWA</td>
          <td>Salty Shallow Water</td>
          <td style="background-color:#6e0096;"></td>
        </tr>
        <tr>
          <td>25</td>
          <td>CL</td>
          <td>Cloud</td>
          <td style="background-color:#e6e6e6;"></td>
        </tr>
        <tr>
          <td>26</td>
          <td>SMKPLM</td>
          <td>Smoke Plume</td>
          <td style="background-color:#5a466e;"></td>
        </tr>
        <tr>
          <td>27</td>
          <td>TNCLV</td>
          <td>Thin clouds over vegetation</td>
          <td style="background-color:#c8e1af;"></td>
        </tr>
        <tr>
          <td>28</td>
          <td>TNCLWA_BB</td>
          <td>Thin clouds over water area or Barren land or Built-up areas</td>
          <td style="background-color:#bebef5;"></td>
        </tr>
        <tr>
          <td>29</td>
          <td>SN</td>
          <td>Snow or water ice</td>
          <td style="background-color:#1effff;"></td>
        </tr>
        <tr>
          <td>30</td>
          <td>SHSN</td>
          <td>Shadow Snow</td>
          <td style="background-color:#00648c;"></td>
        </tr>
        <tr>
          <td>31</td>
          <td>SH</td>
          <td>Shadow areas</td>
          <td style="background-color:#14143c;"></td>
        </tr>
        <tr>
          <td>32</td>
          <td>FLAME</td>
          <td>Flame</td>
          <td style="background-color:#ff1446;"></td>
        </tr>
        <tr>
          <td>33</td>
          <td>UN</td>
          <td>Unknown</td>
          <td style="background-color:#ff0000;"></td>
        </tr>
        <tr>
          <td>255</td>
          <td>NO DATA</td>
          <td>No data</td>
          <td style="background-color:#000000;"></td>
        </tr>
      </tbody>
    </table>

  </app-resources-page-section>

  <app-resources-page-section title="Examples">

    <h3>Example 1: Forest fire in Teneriffe, Spain</h3>

    <p>An example workflow with color33 may encompass the following steps</p>

    <ul>
      <li>Obtain a satellite image stack using color33's semantic enrichment</li>
      <li>Use semantic content-based image retrieval to select cloud free image before and after. An example for a content-based criterion is the cloud cover, which is evaluated on the user-defined area-of-interest and not on the entire image scene</li>
      <li>Use the open source Semantique Python package to analyse the images, e.g., for performing an automated change detection</li>
      <li>Step 1: Combine vegetation categories into class vegetation</li>
      <li>Step 2: Look for vegetation decrease or increase in both images</li>
      <li>Step 3: Create a change map</li>
    </ul>

    <app-resources-page-note>
      The example above can be conducted <b>fully automated</b> without any manual intervention. The user only needs to define the area-of-interest. The example is based on the assumption that the user is familiar with Python and the Semantique package. The example is a simple one and can be extended to more complex analyses.
    </app-resources-page-note>

    <p>The following figure illustrates the example workflow using the forest fire in Teneriffe, Spain </p>

    <app-resources-page-image file="/assets/examples/workflow_example_1.jpg">
      Illustration of the example workflow using the forest fire in Teneriffe, Spain. The image stack is obtained using color33's semantic enrichment. The images are selected using a content-based criterion (e.g., cloud cover). The images are analysed using the Semantique Python package to detect changes in vegetation.
    </app-resources-page-image>

    <app-resources-page-image file="/assets/examples/workflow_example_2.jpg">
      Detail view of the change map of the forest fire in Teneriffe, Spain, obtained using the example workflow and color33's semantic enrichment.
    </app-resources-page-image>

    <h3>Example 2: Vegetation occurrences and changes</h3>
    <p>Creating information from a stack of color33's categories is easy. The following figure shows how a time series of vegetation categories can be condensed to a single map showing the occurrences of vegetation over time.</p>

    <app-resources-page-image file="/assets/examples/workflow_example_3.jpg">
      Vegetation occurrences over time. The greener the area, the more often vegetation was observed.
    </app-resources-page-image>

    <p>This approach can be used for multiple years to delineate changes such as a landslide as the following example shows. All red areas are affeced by the landslide</p>
    <app-resources-page-image file="/assets/examples/workflow_example_4.jpg">
      landslide detection using color33's semantic enrichment. The red areas are affected by the landslide.
    </app-resources-page-image>


    <app-resources-page-note>
      These are only selected examples to showcase the potential of color33's semantic enrichment. The examples are simple and can be extended to more complex analyses. Contact us to discuss your specific use case!
    </app-resources-page-note>

  </app-resources-page-section>

  <app-resources-page-section title="Visualization in a GIS (Geographical Information Systems)">

    <p>color33 outputs are categorical variables, which can be visualized using associated colors. Although, you can choose any color in your GIS or other visualization software, we recommend the following color palette:</p>


        <p>
          <a href="/assets/visualisation-styles/color33-Style.lyrx" target="_blank" class="internal-link">
            <img src="/assets/visualisation-styles/style-icon.png" alt="Brochure Icon" class="internal-link-icon">Visualization file for ArcGIS (*.lyrx)</a> (&lt;1 Mb).
        </p>
        <p>
          <a href="/assets/visualisation-styles/color33-Style.qml" target="_blank" class="internal-link">
            <img src="/assets/visualisation-styles/style-icon.png" alt="Brochure Icon" class="internal-link-icon">Visualization file for QGIS (*.qml)</a> (&lt;1 Mb).
        </p>

  </app-resources-page-section>

  <!--<h2>First steps using the service</h2>-->
</app-resources-wrapper>
