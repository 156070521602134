<div *ngFor="let result of resultList">
  <app-result [result]="result">
  </app-result>
</div>

<div class="list-action">
  <button
  mat-stroked-button>
    Select all
  </button>

  <button
    mat-stroked-button>
      Select none
  </button>

  <button
  mat-stroked-button>
    Download all
  </button>

  <button
    mat-stroked-button>
      Download selected
  </button>
</div>