<div class="dialog">
  <div class="message">{{data.message}}</div>
  <div class="actions-wrapper">
    <div class="dialog-actions">
      <button
        mat-stroked-button
        color="warn"
        (click)="this.onYesClick()">Yes</button>
      <button
        mat-stroked-button
        (click)="this.onNoClick()">No</button>
    </div>
  </div>
</div>