import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-nav-button',
  templateUrl: './nav-button.component.html',
  styleUrls: ['./nav-button.component.scss']
})
export class NavButtonComponent {
  @Input() label!: string;
  @Input() icon!: string;
  @Input() link!: string;
  @Input() tooltipMessage!: string;
  @Input() small: boolean = false;
  @Input() disabled: boolean = false;
}
