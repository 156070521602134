<app-resources-wrapper context="whatsnew">
  <app-resources-page-title
    [icon]="'list_alt_add'"
    [title]="'What\'s New?'">
  </app-resources-page-title>

  <app-news-item
  [title]="'Updated user frontend and improving processing backend!'"
  [date]="'02.08.2024'">
        <p>We restructured the frontend and transformed the <b>help</b> section into a more informative <b>resource</b> section. The new resource section contains several more information. For example, information about pricing and usage examples were added.</p>
        <p>Since Sentinel-2 data are provided in the UTM projection, the results are provided in the same projection as well. However, re-projection after processing may not as convenient as before processing due to the categorical variables. Therefore, we added a simple warning to the frontend that informs users when selected images are located in different UTM zones. Users can then decide to re-project all of them into a common projection using the processing options.</p>
        <p>Additionally, we improved several steps during the workflow in the backend that makes the processing more efficient.</p>
  </app-news-item>

  <app-news-item
  [title]="'New style, image search, and output options'"
  [date]="'22.05.2024'">
        <p>We replaced the initial design with a new one that requires less space and is more compressed!</p>
        <p>All settings for selecting images fit on one page now and can be directly accessed without scrolling. Further, all buttons have icons that help identify their purpose.</p>
        <p>One of the biggest updates allows users to configure additional image search and processing options. For example, it is now possible to select images with a maximum amount of cloud cover, process images in a specific projection, or obtain the images in a cloud-optimized format. Further, it is possible to download the original Sentinel-2 image along with the results.</p>
        <p>Do you have any questions or problems with color33 or the new features? Feel free to contact us!</p>
  </app-news-item>

  <app-news-item
  [title]="'Public access ready!'"
  [date]="'17.11.2023'">
        <p>After one year of development and co-funded by the European Space Agency, we are happy to announce that color33 service is publicly available now!</p>
        <p>How to get access? Contact us and we will invite you to a demo.</p>
  </app-news-item>

</app-resources-wrapper>