import {Injectable} from '@angular/core';
import {Observable, of, map, catchError, throwError, switchMap} from "rxjs";
import {HttpClient, HttpErrorResponse} from "@angular/common/http";
import {OidcSecurityService} from "angular-auth-oidc-client";
import APP_CONFIG from "../assets/app-config.json";

export interface JobInputs {
    scenes: string[];
    dry_run: boolean;
    from_date: Date;
    to_date: Date;
    epsg_code: number,
    output_layers: string[],
    cloud_cover_min: number,
    cloud_cover_max: number,
    provide_original_satellite_images: string,
    provide_cloud_optimised_output: boolean,
}

export interface JobOutputs {

}

export interface JobResultSceneStats {
    remaining: number;
    successful: number;
    failed: number;
}
export interface JobResults {
    siam_results: object;
    scene_stats: JobResultSceneStats;
}

export interface Job {
    jobID: string;
    message: string;
    status: string;
    processID: string;
    type: string;
    created: Date;
    started: Date;
    finished: Date;
    updated: Date;
    progress: number;
    isValidated: boolean;
    inputs: JobInputs;
    outputs: JobOutputs;
}

export interface JobList {
    count: number;
    next: string;
    previous: string;
    results: Job[];
}


@Injectable({
    providedIn: 'root'
})
export class JobsService {
    readonly BASE_API_URL = APP_CONFIG.API.BASE_URL;
    readonly JOBS_URL = `${this.BASE_API_URL}/jobs/`;
    readonly SIAM_EXECUTE_URL = `${this.BASE_API_URL}/processes/siamaas/execute`;

    constructor(private http: HttpClient,
                private oidcSecurityService: OidcSecurityService) {
    }

    getJobList(nextPage?: string): Observable<JobList> {
        return this.oidcSecurityService.getAccessToken()
            .pipe(
                switchMap((token: string) => {
                    return this.http.get<JobList>(nextPage? nextPage : this.JOBS_URL,
                        {headers: {'Authorization': 'Bearer ' + token,}}
                    )
                }),
                catchError(this.handleError)
            )
    }

    getJob(id: string): Observable<Job> {
        return this.oidcSecurityService.getAccessToken()
            .pipe(
                switchMap((token: string) => {
                    return this.http.get<Job>(`${this.JOBS_URL}/${id}`,
                        {headers: {'Authorization': 'Bearer ' + token}}
                    )
                }),
                catchError(this.handleError)
            )
    }
    
    getJobResults(id: string): Observable<JobResults> {
        return this.oidcSecurityService.getAccessToken()
            .pipe(
                switchMap((token: string) => {
                    return this.http.get<JobResults>(`${this.JOBS_URL}/${id}/results`,
                        {headers: {'Authorization': 'Bearer ' + token}}
                    )
                }),
                catchError(this.handleError)
            )
    }

    executeJob(siamaasInput: JobInputs): Observable<Job> {
        return this.oidcSecurityService.getAccessToken()
            .pipe(
                switchMap((token: string) => {
                    return this.http.post<Job>(this.SIAM_EXECUTE_URL,
                        {
                            "inputs": siamaasInput,
                            "outputs": {
                                "scene_stats": {"transmissionMode": "value"},
                                "siam_results": {"transmissionMode": "value"}
                            }
                        },
                        {headers: {'Authorization': 'Bearer ' + token}}
                    )
                }),
                catchError(this.handleError)
            )
    }

    deleteJob(id: string): Observable<any> {
        return this.oidcSecurityService.getAccessToken()
            .pipe(
                switchMap((token: string) => {
                    return this.http.delete<any>(`${this.JOBS_URL}/${id}`,
                        {headers: {'Authorization': 'Bearer ' + token}}
                        )
                }),
                catchError(this.handleError)
            )
    }

    private handleError(error: HttpErrorResponse) {
        if (error.status === 0) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('Error connecting to API:', error.error);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong.
            console.error(
                `API returned ${error.status}: `, error.error);
        }
        // Return an observable with a user-facing error message.
        return throwError(() => new Error(error.message));
    }
}
