import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-resources-page-title',
  templateUrl: './resources-page-title.component.html',
  styleUrls: ['./resources-page-title.component.scss']
})
export class ResourcesPageTitleComponent {
  @Input() title: string = "";
  @Input() icon: string = "";
}
