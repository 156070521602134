import {Component, EventEmitter, Input, Output} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {FormGroup, FormControl} from '@angular/forms';


@Component({
  selector: 'app-time-view',
  templateUrl: './time-view.component.html',
  styleUrls: ['./time-view.component.scss'],
})
export class TimeViewComponent {

  @Input() readonly: boolean = true;
  @Input() selectedStartDate!: Date;
  @Input() selectedEndDate!: Date;

  @Output() selectedStartDateChange = new EventEmitter<Date>();
  @Output() selectedEndDateChange = new EventEmitter<Date>();

  constructor(private dateAdapter: DateAdapter<Date>) {
    this.dateAdapter.setLocale('en-AT');
  }

  readonly today = new Date();

  range = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });

  dateRangeChangeTrigger(){
    if (this.range.value.start !== null && this.range.value.end !== null){
      this.selectedStartDateChange.emit(this.range.value.start);
      this.selectedEndDateChange.emit(this.range.value.end);
    }
  }
}
