import { inject, NgModule } from '@angular/core';
import { Router, RouterModule, Routes } from '@angular/router';
import { LandingpageComponent } from './landingpage/landingpage.component';
import { JoblistComponent } from './joblist/joblist.component';
import { JobComponent } from './job/job.component';
import { SettingsComponent } from './settings/settings.component';
import { MeComponent } from './me/me.component';
import { ResourcesComponent } from './resources/resources.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ResultsComponent } from './job/results/results.component';
import { DownloadComponent } from './job/results/download/download.component';
import { WhatsNewComponent } from './resources/whats-new/whats-new.component';
import { GettingStartedComponent } from './resources/getting-started/getting-started.component';
import { ManualComponent } from './resources/manual/manual.component';
import { ContactComponent } from './resources/contact/contact.component';
import { CookiesComponent } from './legal/cookies/cookies.component';
import { PrivacyComponent } from './legal/privacy/privacy.component';
import { SecurityComponent } from './legal/security/security.component';
import { ImprintComponent } from './legal/imprint/imprint.component';
import { PricingComponent } from './resources/pricing/pricing.component';
import { AgreementsComponent } from './legal/agreements/agreements.component';

import {AutoLoginPartialRoutesGuard, OidcSecurityService} from 'angular-auth-oidc-client';
import {LoginRedirectComponent} from './login-redirect/login-redirect.component'
import { map, take } from 'rxjs/operators';

export const isAuthenticated = () => {
    const oidcSecurityService = inject(OidcSecurityService);
    const router = inject(Router);

    return oidcSecurityService.isAuthenticated$.pipe(
        take(1),
        map(({isAuthenticated}) => {
            if (!isAuthenticated) {
                router.navigate(['']);
                return false;
            }
            return true;
        })
    );
};

const routes: Routes = [
    {path: '', component: LandingpageComponent},
    {path: 'login', component: LoginRedirectComponent},
    {path: 'jobs', component: JoblistComponent, canActivate: [isAuthenticated]},
    {path: 'job/:jobid', component: JobComponent, canActivate: [isAuthenticated]},
    {path: 'job/:jobid/results', component: ResultsComponent, canActivate: [isAuthenticated]},
    {path: 'job/:jobid/results/download', component: DownloadComponent, canActivate: [isAuthenticated]},
    {path: 'job', redirectTo: '/new', pathMatch: 'full'},
    {path: 'new', component: JobComponent, canActivate: [isAuthenticated]},
    {path: 'me', component: MeComponent, canActivate: [isAuthenticated]},
//  {path: 'me', component: MeComponent, canActivate: [AutoLoginPartialRoutesGuard]},
//  {path: 'settings', component: SettingsComponent},
  {path: 'resources', component: ResourcesComponent},
  {path: 'resources/whats-new', component: WhatsNewComponent},
  {path: 'resources/getting-started', component: GettingStartedComponent},
  {path: 'resources/manual', component: ManualComponent},
  {path: 'resources/contact', component: ContactComponent},
  {path: 'resources/pricing', component: PricingComponent},  
  {path: 'legal/cookies', component: CookiesComponent},
  {path: 'legal/privacy', component: PrivacyComponent},
  {path: 'legal/security', component: SecurityComponent},
  {path: 'legal/imprint', component: ImprintComponent},
  {path: 'legal/agreements', component: AgreementsComponent},  
  {path: '**', component: NotFoundComponent },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
