import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-resources-page-image',
  templateUrl: './resources-page-image.component.html',
  styleUrls: ['./resources-page-image.component.scss']
})
export class ResourcesPageImageComponent {
  @Input() file:String = "";
}
