import { Component, Input, OnInit } from '@angular/core';

export interface jobLabel {
  key: string;
  tooltip: string;
  color: string;
}

@Component({
  selector: 'app-labels-view',
  templateUrl: './labels-view.component.html',
  styleUrls: ['./labels-view.component.scss']
})

export class LabelsViewComponent {
  @Input() jobLabels?: jobLabel[];
}
