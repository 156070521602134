import { Component } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.scss']
})
export class ResourcesComponent {

  isPhonePortrait = false;

  constructor(private responsive: BreakpointObserver) {

  }

  ngOnInit(){

    this.responsive.observe(Breakpoints.HandsetPortrait)
    .subscribe(result => {

      this.isPhonePortrait = false;

      if (result.matches) {
        this.isPhonePortrait = true;
      }

    });

  }

}
