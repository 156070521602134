<div class="wrapper">

  <div *ngIf="this.jobId; then existingJob else newJob"></div>

  <ng-template #newJob>
    <div class="job-create">
      <mat-card>
        <mat-card-content>
          <h1 class="job-details-id title">
            <span class="material-symbols-outlined">work</span>
            <span>Create new job</span>
            <button
              mat-stroked-button
              color="primary"
              matTooltip="This will start a dry-run to validate your Job."
              [matTooltipPosition]="'below'"
              (click)="validate()"
              aria-label="Start a dry-run to validate the job"
            >
              <mat-icon>assignment</mat-icon>
              <span>Validate with dry-run</span>
            </button>
            <span class="more-menu">
              <button
                mat-icon-button
                [matMenuTriggerFor]="menuProcessingOptions"
                aria-label="More options"
                matTooltip="Click here for more options"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </span>
          </h1>

          <div class="aoi">
          <h2 class="title">
            <mat-icon class="tab-icon">travel_explore</mat-icon>
            <span>Select area of interest</span>
          </h2>
          <app-map-view
            (selectedGranules)="selectedGranulesChanged($event)"
            matTooltip="Click to select single granule. Shift+Click to select multiple"
            [matTooltipPosition]="'above'"
            aria-label="Click to select single granule. Shift+Click to select multiple"
          >
          </app-map-view>
          <mat-form-field class="granule-search">
            <mat-label>Search granule</mat-label>
            <input
              matTooltip="Start typing the granule identifier"
              matInput
              [matAutocomplete]="auto"
              [formControl]="granuleSearch"
              value="">
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngFor="let option of filteredGranulesForSearch | async" [value]="option">
                  {{ option }}
                </mat-option>
              </mat-autocomplete>
          </mat-form-field>
        </div>

          <h2 class="title toi">
            <mat-icon class="tab-icon">date_range</mat-icon>
            <span>Select time interval</span>
          </h2>
          <app-time-view
            [(selectedStartDate)]="this.newJobStartDate"
            [(selectedEndDate)]="this.newJobEndDate"
            (selectedStartDateChange)="this.selectedStartDateChange($event)"
            (selectedEndDateChange)="this.selectedEndDateChange($event)"
            [readonly]="false">
          </app-time-view>
          <app-labels-view [jobLabels]="this.jobLabels"></app-labels-view>
        </mat-card-content>
      </mat-card>

      <mat-card class="job-processing-options" *ngIf="this.customProcessingOptions">
        <mat-card-content>
          <h1 class="title processing-options">
            <span class="material-symbols-outlined">settings</span>
            <span>Image search and output options</span>
            <span class="more-menu">
              <button
                mat-icon-button
                (click)="toggleProcessingOptions()"
                aria-label="Use default and close image search and output options"
                matTooltip="Use default and close image search and output options"
              >
                <mat-icon>close</mat-icon>
              </button>
            </span>
          </h1>
          <app-processing-options
            [defaultProcessingOptions]="this.defaultProcessingOptions"
            (notify)="toggleProjectionsLabel($event)"
          ></app-processing-options>
        </mat-card-content>
      </mat-card>
    </div>

    <mat-menu #menuProcessingOptions="matMenu">

      <button
        mat-menu-item
        matTooltip="Use advanced image search and output options here"
        matTooltipPosition="left"
        aria-label="Click to use advanced image search and output options here"
        (click)="toggleProcessingOptions()"
      >
        <mat-icon>settings</mat-icon>
        <span>{{customProcessingOptions? "Default options" : "Custom options"}}</span>
      </button>

    </mat-menu>
  </ng-template>

  <ng-template #existingJob>
    <div class="job-details" *ngIf="job">
      <mat-card>
        <mat-card-content>
        <h1 class="title">
          <span class="material-symbols-outlined">
            work
          </span>
          <span>
            #{{this.job.jobID}}
          </span>
          <span class="status-icon">
            <span class="succeeded material-symbols-outlined" *ngIf="this.job.status ==='successful'">task_alt</span>
            <span class="failed material-symbols-outlined" *ngIf="this.job.status ==='failed'">cancel</span>
            <span class="started material-symbols-outlined" *ngIf="this.job.status ==='running'">clock_loader_40</span>
            <span class="started material-symbols-outlined" *ngIf="this.job.status ==='accepted'">hourglass</span>
          </span>
          <button *ngIf="this.isDownloadDisabled()"
            mat-stroked-button
            [disabled]="this.job.status !== 'successful'"
            (click)="this.runJob($event, false)"
            color="primary"
            matTooltip="Please check the number of images involved. If this is fine, click here to process them."
            aria-label="Re-run this job in a new wet-run (data will be processed)"
          >
            <mat-icon>repeat</mat-icon>
            <span>Re-run as wet-run</span>
          </button>
          <span class="more-menu">
            <button
              mat-icon-button
              [matMenuTriggerFor]="menu"
              aria-label="More options"
              matTooltip="Click here for more options"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
          </span>
        </h1>

        <app-status-bar
                [createdTimestamp]="job.created"
                [startedTimestamp]="job.started"
                [finishedTimestamp]="job.finished">
        </app-status-bar>
        <div class="message">
          <span class="material-symbols-outlined icon">description</span>
          <span>{{this.job.message}}</span>
        </div>


        <h2>
          <span class="material-symbols-outlined icon">travel_explore</span>
          <span>Geographic area</span>
        </h2>
        <app-map-view [mapFeatues]="job.inputs.scenes"></app-map-view>

        <h2>
          <span class="material-symbols-outlined icon">chronic</span>
          Time interval
        </h2>
        <app-time-view
          [selectedStartDate]="this.job.inputs.from_date"
          [selectedEndDate]="this.job.inputs.to_date"
          [readonly]="true"
        ></app-time-view>
        <app-labels-view [jobLabels]="this.jobLabels"></app-labels-view>

      </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-content >
          <h1>
            <span class="material-symbols-outlined">list</span>
            <span>Results</span>
            <span class="more-menu">
              <button
                mat-icon-button
                [matMenuTriggerFor]="resultMenu"
                aria-label="More options"
                matTooltip="Click here for more options"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </span>
          </h1>

          <div *ngIf="job.status === 'running' || job.status === 'accepted'">
            Job has not finished yet. Please wait until job is finished to download results.
          </div>

          <div *ngIf="(job.status === 'successful' || job.status === 'failed') && this.isDownloadDisabled()">
            <span *ngIf="job.inputs.dry_run">This Job was a dry-run. Dry-runs have no results.</span>
            <span *ngIf="!job.inputs.dry_run">There are no results. They might have been deleted on the server.</span>
          </div>

          <div>
            <mat-accordion *ngIf="jobResults && jobResults.siam_results">
              <mat-expansion-panel *ngFor="let result of jobResults.siam_results | keyvalue">
                <mat-expansion-panel-header>{{result.key}}</mat-expansion-panel-header>
                <ul>
                  <li *ngFor="let url of result.value">
                    <button mat-button style="margin-bottom: 5px" [disabled]="this.job.status !== 'successful' || isDownloadDisabled()"
                            (click)="downloadUrl(url)">
                      {{url.substring(url.lastIndexOf('/')+1)}}
                      <mat-icon>get_app</mat-icon>
                    </button>
                  </li>
                </ul>
              </mat-expansion-panel>
            </mat-accordion>
          </div>

        </mat-card-content>
      </mat-card>

      <mat-card class="job-processing-options" *ngIf="this.customProcessingOptions">
        <mat-card-content>
          <h1 class="title processing-options">
            <span class="material-symbols-outlined">settings</span>
            <span>Image search and output options</span>
            <span class="more-menu">
              <button
                mat-icon-button
                [matMenuTriggerFor]="processingOptionsMenu"
                aria-label="Hide image search and output options"
                matTooltip="Hide image search and output options"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
            </span>
          </h1>
          <app-processing-options
            [existingJob]="this.job.inputs"
            [defaultProcessingOptions]="this.defaultProcessingOptions"
            ></app-processing-options>
        </mat-card-content>
      </mat-card>

      <mat-menu #menu="matMenu">

        <button
          mat-menu-item
          [disabled]="this.job.status !== 'successful'"
          (click)="this.runJob($event, false)"
          matTooltip="Re-run this job in a new wet-run (data will be processed)"
          matTooltipPosition="left"
          aria-label="Re-run this job in a new wet-run (data will be processed)"
        >
          <mat-icon>repeat</mat-icon>
          <span>Re-run as wet-run</span>
        </button>

        <button
          mat-menu-item
          [disabled]="this.job.status !== 'successful'"
          (click)="this.runJob($event, true)"
          matTooltip="Re-run this job in a new dry-run (no data will be processed)"
          matTooltipPosition="left"
          aria-label="Re-run this job in a new dry-run (no data will be processed)"
        >
          <mat-icon>repeat</mat-icon>
          <span>Re-run as dry-run</span>
        </button>

        <button
          mat-menu-item [disabled]="this.job.status !== 'successful' || isDownloadDisabled()"
          (click)="downloadZip()"
          matTooltip="Download all data"
          matTooltipPosition="left"
          aria-label="Download all data"
        >
          <mat-icon>cloud_download</mat-icon>
          <span>Download all results</span>
        </button>

        <button
          mat-menu-item
          matTooltip="Show image search and output options"
          matTooltipPosition="left"
          aria-label="Click to show image search and output options"
          (click)="toggleProcessingOptions()"
        >
          <mat-icon>settings</mat-icon>
          <span>{{customProcessingOptions? "Hide options" : "Show options"}}</span>
        </button>

        <button
          mat-menu-item
          [cdkCopyToClipboard]="this.job.jobID"
          matTooltip="Copy the job identifier (ID) to clipboard"
          matTooltipPosition="left"
          aria-label="Copy the job identifier (ID) to clipboard"
        >
          <mat-icon>content_copy</mat-icon>
          <span>Copy ID to clipboard</span>
        </button>

        <button
          mat-menu-item
          [disabled]="this.job.status !== 'successful' && this.job.status !== 'failed'"
                          (click)="openDeleteDialog('200ms', '200ms', this.confirmDeleteMessage)"
          matTooltip="Delete this job. Attention: This can not be recovered."
          matTooltipPosition="left"
          aria-label="Delete this job. Attention: This can not be recovered."
        >
          <mat-icon color="warn">delete_forever</mat-icon>
          <span>Delete job and results</span>
        </button>

      </mat-menu>

      <mat-menu #resultMenu="matMenu">

        <button
          mat-menu-item [disabled]="this.job.status !== 'successful' || isDownloadDisabled()"
          (click)="downloadZip()"
          matTooltip="Download all data"
          matTooltipPosition="left"
          aria-label="Download all data"
        >
          <mat-icon>cloud_download</mat-icon>
          <span>Download all</span>
        </button>

      </mat-menu>


      <mat-menu #processingOptionsMenu="matMenu">

        <button
          mat-menu-item
          (click)="toggleProcessingOptions()"
          matTooltip="Hide image search and output options"
          matTooltipPosition="left"
          aria-label="Hide image search and output options"
        >
          <mat-icon>settings</mat-icon>
          <span>Hide options</span>
        </button>

      </mat-menu>
    </div>
  </ng-template>
</div>


