
<app-resources-wrapper context="pricing">
  <app-resources-page-title
    [icon]="'payments'"
    [title]="'Pricing'">
  </app-resources-page-title>

  <p>
    Use color33 for a variety of purposes with flexible plans that fits best to your project:
  </p>

  <div class="price-options-container">
    <app-price-option [option]="'bronze'"></app-price-option>
    <app-price-option [option]="'silver'"></app-price-option>
    <app-price-option [option]="'gold'"></app-price-option>
    <app-price-option [option]="'platinum'"></app-price-option>
  </div>

  <h2>Price list: </h2>

  <table class="table">
    <tbody>
      <tr>
        <td>Analysis of one Sentinel-2 scene</td>
        <td>3 credits</td>
      </tr>
      <tr>
        <td>Storage of the result of one Sentinel-2 scene for one month</td>
        <td>2 credits</td>
      </tr>
    </tbody>
  </table>

  <h2>Example credit needs: </h2>
  <p>Austia: covered by ~60 Sentinel-2 scenes for one period in time = ~300 credits (for analysis and one months storage)</p>

  <h2>Flexibility</h2>
  <p>We can cover flexible needs tailored for your application: You can also get credits on a pay-per-use basis. </p>

  <app-resources-page-note>
    Discounts apply for higher quantities and education or research projects.
  </app-resources-page-note>

  <app-resources-page-note>
    Credits are valid for 12 months.
  </app-resources-page-note>

  <p>
    A detailed overview can be also found in our
    <a href="/assets/pricing/color33-pricing-en.pdf" target="_blank" class="internal-link">
      <img src="/assets/brochure-icon.png" alt="Brochure Icon" class="internal-link-icon">pricing brochure</a> (1.3 Mb).
  </p>

  <p>You need more credits? You are in the field of education or research? You have specific requirements?</p>

  <app-call-to-action
    [position]="'inline'"
    [label]="'Contact us'"
    [color]="'accent'"
    [subject]="'Color33%20pricing%20request'">
  </app-call-to-action>

  <!--
  <div class="image-container">
    <img src="/assets/pricing/cooperation.png" />
  </div>
  -->


</app-resources-wrapper>