import {OnInit, Component, AfterViewInit, Input, SimpleChanges, Output, EventEmitter} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import Map from 'ol/Map';
import View from 'ol/View';
import {OSM, XYZ} from 'ol/source';
import TileLayer from 'ol/layer/Tile';
import VectorSource from 'ol/source/Vector';
import Draw from 'ol/interaction/Draw';
import VectorLayer from 'ol/layer/Vector';
import {GeoJSON} from "ol/format";
import {fromLonLat, transformExtent} from "ol/proj";
import {bbox as bboxStrategy} from 'ol/loadingstrategy';
import {Extent, Select} from "ol/interaction";
import {click} from "ol/events/condition";
import {Fill, Stroke, Style, Text} from "ol/style";
import {Feature} from "ol";

export type StyleFunction = (feature: any, resolution: number) => Style;
export type WFSLayerType = {name: string, url: string, layer: VectorLayer<any>};
@Component({
    selector: 'app-map-view',
    templateUrl: './map-view.component.html',
    styleUrls: ['./map-view.component.scss']
})
export class MapViewComponent implements OnInit, AfterViewInit {
    @Input() mapFeatues!: string[];
    @Output() selectedGranules = new EventEmitter<string[]>();

    public map!: Map;
    private basemap!: TileLayer<XYZ>;

    private granuleLayer: WFSLayerType = {
            name: 'Granules',
            url: 'https://ows.sen2cube.at/geoservice/?SERVICE=WFS' +
                    '&REQUEST=GetFeature&TYPENAME=sentinel-2-tiling-grid&SRSName=EPSG:4326' +
                    '&OUTPUTFORMAT=geojson',
            layer: new VectorLayer<any>
    };

    private utmGridLayer: WFSLayerType = {
        name: 'World UTM Grid',
        url: 'https://ows.sen2cube.at/geoservice/?SERVICE=WFS' +
                    '&REQUEST=GetFeature&TYPENAME=world_utm_grid&SRSName=EPSG:4326' +
                    '&OUTPUTFORMAT=geojson',
        layer: new VectorLayer<any>
    };

    private view!: View;

    private isInitialised: Boolean = false;

    constructor(private http: HttpClient) { }

    ngOnInit(): void {
        const carto = new TileLayer({
            source: new XYZ({
                url: 'http://{1-4}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png',
            })
        });
        const osm = new TileLayer({
            source: new OSM(),
        });
        this.basemap = carto;

        this.view = new View({
            center: fromLonLat([13.2298181495, 47.7354457668], 'EPSG:3857'),
            zoom: 8, maxZoom: 18,
        });
    }

    private updateWFSLayer(WFSLayer: WFSLayerType, style: StyleFunction): void{
        if (WFSLayer.layer) {
            this.map.removeLayer(WFSLayer.layer)
        }
        const that = this;
        const source = new VectorSource({
            format: new GeoJSON(),
            url: function (extent) {
                let WFSUrl = WFSLayer.url;
                if (that.mapFeatues && that.mapFeatues.length > 0) { // if we have given map
                    WFSUrl += `&EXP_FILTER=Name in ('${that.mapFeatues.join("','")}')`
                } else {
                    const transformedExtent = transformExtent(extent, 'EPSG:3857', 'EPSG:4326');
                    WFSUrl += '&BBOX='+transformedExtent.join(',')
                }
                return WFSUrl;
            },
            strategy: bboxStrategy,
        });
        source.on('featuresloadend', (event)=> {
            if (that.mapFeatues && that.mapFeatues.length > 0) { // this prevents zooming around endlessly
                that.map.getView().fit(
                    WFSLayer.layer.getSource().getExtent(),
                    {size:that.map.getSize(), maxZoom:16})
            }
        });
        const vectorLayer = new VectorLayer({
                source: source,
                style: style,
                declutter: true,
            }
        );
        WFSLayer.layer = vectorLayer;
        this.map.addLayer(WFSLayer.layer);
    }

    private adjustTextSizeWithResolution (resolution: number, weight: string) {
        switch (true) {
            case resolution < 300:
                return weight + " 38px Arial";
            case resolution < 650 && resolution >= 300:
                return weight + " 28px Arial";
            case resolution < 1300 && resolution >= 650:
                return weight + " 18px Arial";
            case resolution > 1300 && resolution >= 2500:
                return weight + " 12px Arial";
            case resolution > 2500:
                return weight + " 10px Arial";
            default:
              return weight + " 10px Arial"
          }
    }

    private defaultGranuleStyle = (feature: any, resolution: number) => {
        return new Style({
            stroke: new Stroke({
                color: 'lightgrey',
                width: 0.75,
              }),
            fill: new Fill({
                color: 'rgba(200,200,200,0.25)',
              }),
            text: new Text({
                font: this.adjustTextSizeWithResolution(resolution, ""),
                text: resolution < 4500 ? feature.get("Name"): "",
                declutterMode: "obstacle",
                fill: new Fill({
                    color: "gray"
                }),
                stroke: new Stroke({
                    color: "white",
                    width: 1
                }),
            })
        })
    }

    private selectedGranuleStyle = (feature: any, resolution: number) => {
        return new Style({
            stroke: new Stroke({
                color: 'rgba(103, 58, 183, .6)',
                width: 2,
              }),
              fill: new Fill({
                color: 'rgba(153, 108, 233, 0.3)',
              }),
            text: new Text({
                font: this.adjustTextSizeWithResolution(resolution, "bold"),
                text: resolution < 4500 ? feature.get("Name"): "",
                declutterMode: "obstacle",
                fill: new Fill({
                    color: "rgba(103, 58, 183, .6)"
                }),
                stroke: new Stroke({
                    color: "white",
                    width: 1
                }),
            })
        })
    }

    private defaultUTMGridStyle = (feature: any, resolution: number) => {
        return new Style({
            stroke: new Stroke({
                color: 'lightblue',
                width: 0.75,
              }),
            fill: new Fill({
                color: 'rgba(255,255,255,0)',
              }),
            text: new Text({
                font: this.adjustTextSizeWithResolution(resolution, ""),
                text: resolution < 4500 ? feature.get("ZONE").toString() + feature.get("ROW_"): "",
                declutterMode: "obstacle",
                fill: new Fill({
                    color: "lightblue"
                }),
                stroke: new Stroke({
                    color: "white",
                    width: 1
                }),
            })
        })
    }
    ngAfterViewInit(): void {
        this.initMap();
        this.updateWFSLayer(this.utmGridLayer, this.defaultUTMGridStyle);
        this.updateWFSLayer(this.granuleLayer, this.defaultGranuleStyle);
    }

    /*
     * initMap function:
     *  this function can be called from the component
     *  itself, but also from outside. The reason is
     *  lazy loading in the tab.
     *  However, the function will be executed only one
     *  and set the variable isInitialised to true.
     */
    initMap(): void {
        if (this.isInitialised) {
            return;
        }
        this.map = new Map({
            layers: [this.basemap],
            target: 'ol-map',
            view: this.view,
        });

        if (!this.mapFeatues || !(this.mapFeatues.length > 0)) {

            const selectInteraction = new Select({
                condition: click,
                style: this.selectedGranuleStyle,
            });

            selectInteraction.on('select',  (e) => {
                const selectedFeatures = e.target.getFeatures().getArray();
                const selectedGranules = selectedFeatures.map((feature: Feature) => feature.get('Name'));
                this.selectedGranules.emit(selectedGranules);
            });

            this.map.addInteraction(selectInteraction)
        }

        this.isInitialised = true;
    }

    panToFeatureExtent(features:any): void {
        let extent = new GeoJSON().readGeometry(
            features[0].geometry, {featureProjection: 'EPSG:3857'}
            ).getExtent();
        this.map.getView().fit(extent)
    }

    /*
     * destroyMap function:
     *  this function can be called from outside to reset the
     *  map.
     *  It resets the varibale isInitialised to false
     */
    destroyMap(): void {
        this.isInitialised = false;
    }
}
