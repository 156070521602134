import { Component, Input } from '@angular/core';

export interface Result {
  name: string;
  completed: boolean;
  files?: Result[];
}

@Component({
  selector: 'app-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class ResultComponent {

  @Input() result!: Result;

  allComplete: boolean = false;

  updateAllComplete() {
    this.allComplete = this.result.files != null && this.result.files.every(f => f.completed);
  }

  someComplete(): boolean {
    if (this.result.files == null) {
      return false;
    }
    return this.result.files.filter(f => f.completed).length > 0 && !this.allComplete;
  }

  setAll(completed: boolean) {
    this.allComplete = completed;
    if (this.result.files == null) {
      return;
    }
    this.result.files.forEach(f => (f.completed = completed));
  }
}
