import {Component, OnInit} from '@angular/core';
import {OidcSecurityService} from "angular-auth-oidc-client";

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrls: ['./download.component.scss']
})

export class DownloadComponent implements OnInit {
  constructor(private oidcSecurityService: OidcSecurityService) {
  }

  ngOnInit(): void {
    this.oidcSecurityService.getAccessToken()
        .subscribe((token) => {
          window.open(`https://api.color33.io/ogc-api/jobs/33325ef2-8775-4b15-a52f-bef137016c98/results/zip?token=${token}`, "_blank");
        })
  }

}
