<button
  mat-button
  [class.mat-mdc-raised-button]="this.selected !== 'selected'"

  class="starter {{this.size}}"
  routerLink="{{this.link}}"
  matTooltip="{{this.tooltipText}}"
  >
  <div class="icon">
    <span class="material-symbols-outlined">
      {{this.icon}}
    </span>
  </div>
  <div class="text">{{this.text}} </div>
</button>