import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-call-to-action',
  templateUrl: './call-to-action.component.html',
  styleUrls: ['./call-to-action.component.scss']
})
export class CallToActionComponent {
  @Input() position!: string;
  @Input() label!: string;
  @Input() color!: string;
  @Input() subject!: string;
  @Input() action!: string;
  @Input() onPhone!: boolean;
}
