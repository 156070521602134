import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-resources-page-section',
  //templateUrl: './resources-page-section.component.html',
  styleUrls: ['./resources-page-section.component.scss'],
  template: `
  <div>
    <h2 class="title" (click)="collapse()">
      <span [ngClass]="this.collapsed ? 'material-symbols-outlined collapsed' : 'material-symbols-outlined'">
        expand_less
      </span>
      <span [ngClass]="this.collapsed ? 'material-symbols-outlined' : 'material-symbols-outlined collapsed'">
        expand_more
      </span>
      {{title}}
    </h2>
    <div [ngClass]="this.collapsed ? 'collapsed' : ''">
    <ng-content></ng-content>
    </div>
    <div [ngClass]="this.collapsed ? 'placeholder' : 'placeholder collapsed'"> ... </div>
  </div> `
})
export class ResourcesPageSectionComponent {
  @Input() title!: String;

  collapsed: Boolean = false;

  collapse(){
    this.collapsed = !this.collapsed;
  }
}
