<div
  class="wrapper"
  [class.mobile]="this.isPhonePortrait">
<div
  class="claim textoverlay landingpage-item"
  [class.mobile] = "this.isPhonePortrait"
  [class.web] = "!this.isPhonePortrait"
>
  <img class="claim-image" src="/assets/siam-claim.jpeg"/>
  <div class="text centered">
    From pixels to insights
</div>

  <div
    class="landingpage-button textoverlay centered"
  >
    <button
      mat-raised-button
      routerLink="/resources/getting-started">
      Get started!</button>
  </div>

  <div class="badge-background"></div>
  <img class="badge" src="/assets/copma_label.png" />
</div>

<div class="introduction landingpage-item">

  <p>color33 is an automated, cloud-based, online processing service that converts multispectral Earth observation images into actionable categories without requiring any training samples.</p>

  <p>Unlike unsupervised clustering routines or machine learning approaches, color33 is based on a physical-model, producing a known set of spectral categories with known semantic associations. The service is parameter-free, fully-automated and application-independent.</p>

</div>

<mat-grid-list
  cols="{{this.applicationsColumns}}"
  rowHeight="2:1"
  class="applications landingpage-item"
  [class.mobile]="this.isPhonePortrait">
  <mat-grid-tile>
    <div class="application">
      <div class="application-icon">
        <span class="material-symbols-outlined">
          question_exchange
          </span>
      </div>
      <div>
      Automate bi-temporal change detection
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="application">
      <div class="application-icon">
        <span class="material-symbols-outlined">
          monitoring
          </span>
      </div>
      <div>
        Support calculating occurrence of spectrally similar observations over time
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="application">
      <div class="application-icon">
        <span class="material-symbols-outlined">
          flex_wrap
          </span>
      </div>
      <div>
        Stratify images, so that algorithms only consider pixels that are similar in a multi-spectral space rather than processing entire images
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="application">
      <div class="application-icon">
        <span class="material-symbols-outlined">
          scatter_plot
          </span>
      </div>
      <div>
        Stratify training sample collection for machine-learning algorithms to ensure that your samples represent features of interest across the multi-spectral feature space they inhabit
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="application">
      <div class="application-icon">
        <span class="material-symbols-outlined">
          compass_calibration
          </span>
      </div>
      <div>
        Validate reflectance values from newly developed calibration or correction routines
      </div>
    </div>
  </mat-grid-tile>
  <mat-grid-tile>
    <div class="application">
      <div class="application-icon">
        <span class="material-symbols-outlined">
          query_stats
          </span>
      </div>
      <div>
        Help you explore the spectral characteristics, spatio-temporal dynamics and heterogeneity of imagery content prior to application-specific analysis
      </div>
    </div>

  </mat-grid-tile>
</mat-grid-list>

<div
  class="video textoverlay landingpage-item"
  [class.mobile] = "this.isPhonePortrait"
  [class.web] = "!this.isPhonePortrait"
>
  <video width="100%" playsinline autoplay muted loop>
    <source src="/assets/flyover.mp4" type="video/mp4">
    <source src="/assets/flyover.webm" type="video/webm">
    Your browser does not support the video tag.
  </video>
  <div class="text centered">
    From reflectance values to stable categories
  </div>
</div>

<div
  class="trust landingpage-item"
  [class.mobile]="this.isPhonePortrait">
  <div class="trust-claim">
    Trusting color33
  </div>

  <mat-grid-list cols="{{this.trustColumns}}" rowHeight="2:1">
    <mat-grid-tile>
      <span>
        <img class="trust-icon left" src="/assets/landingpage-image.png" />
      </span>
      <span>
        <div class="trust-value">
          3259
        </div>
        <div class="trust-metric">
          Images processed
        </div>
      </span>
    </mat-grid-tile>
    <mat-grid-tile>
      <span>
        <div class="trust-value">
          5
        </div>
        <div class="trust-metric">
          Users
        </div>
      </span>
      <span>
        <img class="trust-icon right" src="/assets/landingpage-user.png" />
      </span>
    </mat-grid-tile>
    <mat-grid-tile>
      <span>
        <img class="trust-icon left" src="/assets/landingpage-application.png" />
      </span>
      <span>
        <div class="trust-value">
          3
        </div>
        <div class="trust-metric">
          Applications supported
        </div>
      </span>
    </mat-grid-tile>
    <mat-grid-tile>
      <span>
        <div class="trust-value">
          100%
        </div>
        <div class="trust-metric">
          Happy customers
        </div>
      </span>
      <span>
        <img class="trust-icon right" src="/assets/landingpage-happy-user.png" />
      </span>
    </mat-grid-tile>
  </mat-grid-list>
</div>

<div class="landingpage-item funding">
  <div class="text centered">
    color33 is co-funded by the European Space Agency.
  </div>
  <img class="esa-logo" src="/assets/esa_logo.png" />
</div>

<mat-divider class="landingpage-item"></mat-divider>

<div
  class="main-logos landingpage-item"
  [class.web] = "!this.isPhonePortrait"
  [class.mobile] = "this.isPhonePortrait"
>

      <div class="bottom-stakeholder">
        <a>color33 is developed by Spatial Services GmbH and the Department of Geoinformatics, University of Salzburg with funding from the ESA InCubed Programme.</a>
        <a href="https://spatial-services.com" target="_blank">Spatial Services</a>
        <a href="https://www.plus.ac.at/geoinformatik/?lang=en" target="_blank">Department of Geoinformatics</a>
        <a href="https://incubed.esa.int" target="_blank">ESA InCubed</a>
        <a routerLink="/help/contact">Contact</a>
      </div>

      <div class="siamaas-logo">
        <img src="/assets/logo.jpg" >
      </div>

</div>
<!--
<app-call-to-action
  [position]="'highlight'"
  [label]="'Request Demo'"
  [color]="'accent'"
  [action]="'/help/contact'"
  [onPhone]="this.isPhonePortrait"
>
</app-call-to-action>
-->

</div>