import { Component } from '@angular/core';

@Component({
  selector: 'app-resources-page-note',
  templateUrl: './resources-page-note.component.html',
  styleUrls: ['./resources-page-note.component.scss']
})
export class ResourcesPageNoteComponent {

}
